import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { ThemeStore, ThemeProvider } from './contexts/Theme';
import ModalsProvider from './contexts/Modals';
import { AccountContext, AccountProvider } from './contexts/Account/AccountContext';
import config from './config';
import { getAnalytics, logEvent } from "firebase/analytics";
import './App.css';

// Views
import Home from './views/Home/Home';
import Profile from './views/Profile/Profile';
import Circle from './views/Circle/Circle';
import Community from './views/Community/Community';
import Skill from './views/Skill/Skill';
import Campaign from './views/Campaign/Campaign';

import firebaseApp, { database, analytics, auth, functions } from './firebase';
import { onAuthStateChanged } from "firebase/auth";
import { ref, onValue } from "firebase/database";

// Firebase Emulator Requirements
import { getApp } from "firebase/app";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { WalletProvider } from './contexts/Account/WalletContext';
import TagManager from 'react-gtm-module';
import { theme } from 'highcharts';

if ( window.location.hostname === 'localhost' ) {
  connectAuthEmulator(getAuth(), "http://localhost:9099");
  connectDatabaseEmulator(getDatabase(), "localhost", 9000);
  connectFunctionsEmulator(getFunctions(), "localhost", 5001);
} else {
    const tagManagerArgs = {
        gtmId: 'GTM-K7QJBZ9'
    }
    TagManager.initialize(tagManagerArgs)
}

/*
connectAuthEmulator(auth, "http://localhost:9099");
connectFunctionsEmulator(functions, "localhost", 5001);
connectDatabaseEmulator(database, "localhost", 9000);
*/

const App: React.FC = () => {
//   const { accountUser } = useContext(AccountContext);

//   console.log(accountUser)
  
  return (
    <Providers>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/community" exact>
          <Community />
        </Route>
        <Route path="/profile/:id">
          <Profile />
        </Route>
        <Route path="/circle/:id">
          <Circle />
        </Route>
        <Route path="/skill/:id">
          <Skill />
        </Route>
        <Route path="/c/:id">
          <Campaign />
        </Route>
      </Switch>
    </Providers>
  );
};

const Providers: React.FC = ({ children }) => {
  const firebase = firebaseApp
  const history = useHistory()
  useEffect(() => {
    /*
    return history.listen((location, action) => {
      logEvent(analytics, 'page_view', {
        'page_location': location.pathname + location.search + location.hash,
      })
   })
   */
  },[history]) 

  return (
    <ThemeStore>
        <WalletProvider>
            <ThemeProvider>
                <ModalsProvider>
                    <AccountProvider>
                    <>
                        {children}
                    </>
                    </AccountProvider>
                </ModalsProvider>
            </ThemeProvider>
        </WalletProvider>
    </ThemeStore>
  );
};

export default App;