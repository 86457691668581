import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Button from '../Button';
import { AccountContext } from '../../contexts/Account/AccountContext';
import useModal from 'hooks/useModal';
import DisconnectModal from 'components/DisconnectModal';
import { signOut } from "firebase/auth";
import { auth, functions, database } from '../../firebase';
import { WalletContext } from 'contexts/Account/WalletContext';


interface AccountButtonProps {
    size?: any
}

const AccountButton: React.FC<AccountButtonProps> = ({ size="sm" }) => {
  const { connected, connect, disconnect } = useContext(WalletContext)

  const [onPresentDisconnectModal, onDismissDisconnectModal] = useModal(
    <DisconnectModal 
      onConfirm={() => {
        signOut(auth).then(() => {
            disconnect()
        }).catch((error) => {
            // An error happened.
        });
        onDismissDisconnectModal()
      }}
      onDismiss={() => {
  
      }}
    />
  )

  const connectWallet = async (): Promise<void> => {
    connect()
  }

  const onSignOut = () => {
    onPresentDisconnectModal()
  }
  return (
    <StyledAccountButton>
      { !connected && 
          <Button
            onClick={() => connectWallet()}           
            size={size}
            text="Connect Wallet"
          />
      }         
    </StyledAccountButton>
  )
}

const StyledAccountButton = styled.div`

`

export default AccountButton