import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Link from '../Link';
import Avatar from '../Avatar';
import { BorderRadius, MobileStyles } from '../../contexts/Theme/Mixins'

var _ = require('lodash');

interface MembersProps {
    user: any
}

const Member: React.FC<MembersProps> = ({
    children,
    user
}) => { 
    return (
    <UserWrap>
        <Link to={`/profile/${user.address}`}>
            <Left>
                <User>
                    <Avatar img={user.profile.avatar} />
                </User>
            </Left>
            <Right>
                <Name>{ user.profile.name }</Name>
                <Content>
                    { children }
                </Content>
            </Right>     
        </Link>
    </UserWrap>
    );
};

export default Member;



const Left = styled.span `

`

const Right = styled.span `
    
`

const Name = styled.span `
    display: block;
    cursor: pointer;
    font-weight: bold;
`

const Content = styled.span `
    font-size: 12px;
`
// ${BorderRadius()}
const UserWrap = styled.div `
    cursor: pointer;
    a, label {
        cursor: pointer;
    }

    div > a {        
        display: flex;
        align-items: center;
        
        &:hover {
            color: ${props => props.theme.color.ice};
        }

        .left {
            width: 50px;
            margin-right: 0px;
        }
        .right {
            padding-top: 5px;
            flex: 1;
        }
    }

    -webkit-column-break-inside: avoid;
    :after {
        content: '';
        display: block;
        height: 0px;
    }
    border-radius: 15px;
`

const User = styled.span `
    display: flex;
    align-items: center;
    padding: 5px 10px;
    margin-bottom: 10px;
    cursor: pointer;

    .avatar {
        margin-right: 15px;
    }

    ${MobileStyles(`
        padding: 0px;
        margin-bottom: 0px;
    `)};
`
