import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import Avatar from '../Avatar';
import Link from '../Link';
import { stringToURLSlug } from '../../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faHandsHelping, faTrophy, faKey, faUserTie, faCheck, faCheckCircle, faCertificate } from '@fortawesome/free-solid-svg-icons';
import { AccountContext } from '../../contexts/Account/AccountContext';
import { getCampaigns, getNFTinfo } from '../../ice-water/Galaxy';
import { BorderRadius, DropShadow, MobileStyles } from '../../contexts/Theme/Mixins'
import useModal from '../../hooks/useModal';
import NFTModal from './NFTModal';
import { inherits } from 'util';
var _ = require('lodash');


interface NFTGalleryProps {
    campaigns: [],
    address?: string,
    title?: string
}

const NFTGallery: React.FC<NFTGalleryProps> = ({
    title = 'NFT Rewards',
    campaigns
}) => { 
    const { accountUser } = useContext(AccountContext)
    const [galaxyCampaigns, setGalaxyCampaigns] = useState<any>()

    // Filters
    const [showAssociations, setShowAssociations] = useState<boolean>(true)
    const [showAccess, setShowAccess] = useState<boolean>(true)
    const [showRewards, setShowRewards] = useState<boolean>(true)
    const [showRoles, setShowRoles] = useState<boolean>(true)
    

    const [modalNFT, setModalNFT] = useState<any>()

    useEffect(() => { 
        filterGalaxyCampaigns()
    }, [])

    useEffect(() => { 
        filterGalaxyCampaigns()
    }, [showAssociations, showAccess, showRewards, showRoles])

    function filterGalaxyCampaigns() {
        var filteredCampaigns:any = []
        _.forEach(campaigns, function(campaign:any) {
            // Association
            if ( showAssociations && _.includes(campaign.meta.categories, 'Association') ) {
                filteredCampaigns.push(campaign)
                return
            }
            // Access
            if ( showAccess && _.includes(campaign.meta.categories, 'Access') ) {
                filteredCampaigns.push(campaign)
                return
            }
            // Reward
            if ( showRewards && _.includes(campaign.meta.categories, 'Reward') ) {
                filteredCampaigns.push(campaign)
                return
            }
            // Role
            if ( showRoles && _.includes(campaign.meta.categories, 'Role') ) {
                filteredCampaigns.push(campaign)
                return
            }

            // Remaining
            if ( showAssociations && showAccess && showRewards && showRoles ) {
                filteredCampaigns.push(campaign)
                return
            }
        });
        setGalaxyCampaigns(filteredCampaigns)
    }

    useEffect(() => { 
        //console.log(galaxyCampaigns)
    }, [galaxyCampaigns])

    const showInfoModal = (nft:any) => {
        setModalNFT(nft)    
    }

    useEffect(() => { 
        if ( modalNFT ) {
            onPresentNFTModal()
        }
    }, [modalNFT])

    const [onPresentNFTModal, onDismissNFTModal] = useModal(
        <NFTModal 
            nft={modalNFT}
            onConfirm={() => {
                onDismissNFTModal()
                console.log('confirm')
            }}
            onDismiss={() => {
                console.log('dismiss')
                setModalNFT(null)   
                onDismissNFTModal()
            }}
        />
    )

    return (
    <Content>  
        <Top>
            <TopLeft>
                {/* <h2>{title}</h2>  */}
            </TopLeft>
            <TopRight>
                <label>Filter</label>
                <Filters>
                    {/* <Filter active={false} className='category'>
                        Filter
                    </Filter> */}
                    <Filter onClick={() => {setShowAssociations(!showAssociations)}} active={showAssociations}>
                        <FontAwesomeIcon icon={faHandsHelping} />Associations
                    </Filter>
                    <Filter onClick={() => {setShowAccess(!showAccess)}} active={showAccess}>
                        <FontAwesomeIcon icon={faKey} />Access
                    </Filter>
                    <Filter onClick={() => {setShowRewards(!showRewards)}} active={showRewards}>
                        <FontAwesomeIcon icon={faTrophy} />Rewards
                    </Filter>
                    <Filter onClick={() => {setShowRoles(!showRoles)}} active={showRoles}>
                        <FontAwesomeIcon icon={faUserTie} />Roles
                    </Filter>
                </Filters>
            </TopRight>
        </Top>

        <Gallery>
            { galaxyCampaigns &&
                <>
                { galaxyCampaigns.map((nft:any, index:number) => (
                    <NFT key={`nft_${index}`} onClick={() => {showInfoModal(nft)}}>
                        { nft.claimedTimes ? (
                            <div className='claimed'>
                                <FontAwesomeIcon icon={faCheck} />
                                <div className='claimedOut'>
                                    <div className='claimedInn'>
                                        Claimed!
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        <Details>   
                            <Avatar size={25} img={`https://coordinape-prod.s3.amazonaws.com/ice-full-color_1637711831.png`}
                                icon={<FontAwesomeIcon icon={faHandsHelping} />}
                            />
                            {/* { (_.includes(nft.meta.categories, 'Association')) &&
                                <div className='type'><FontAwesomeIcon icon={faHandsHelping} /></div>
                            }
                            { (_.includes(nft.meta.categories, 'Access')) &&
                                <div className='type'><FontAwesomeIcon icon={faKey} /></div>
                            }
                            { (_.includes(nft.meta.categories, 'Reward')) &&
                                <div className='type'><FontAwesomeIcon icon={faTrophy} /></div>
                            }
                            { (_.includes(nft.meta.categories, 'Role')) &&
                                <div className='type'><FontAwesomeIcon icon={faUserTie} /></div>
                            }       */}
                        </Details>


                        
                        <img src={nft.thumbnail} />
                        {/* <Details>   
                            <Circle>
                                <Avatar size={25} img={`https://coordinape-prod.s3.amazonaws.com/ice-full-color_1637711831.png`}
                                    icon={<FontAwesomeIcon icon={faHandsHelping} />}
                                />
                                <label>
                                    Frosty Apes | 3
                                </label>
                            </Circle>
                        </Details> */}
                        <div className='cover'>
                            <h4>
                                { nft.name }
                            </h4>
                            <div>
                                H2O
                            </div>
                            {/* <label>EPOCH 1</label> */}
                            <div className='line'></div>
                            <div>
                                { nft.meta.categories.map((category:string, index:number) => (
                                    <>
                                        { (_.includes(nft.meta.categories, 'Association')) &&
                                            <div className='type'><FontAwesomeIcon icon={faHandsHelping} /></div>
                                        }
                                        { (_.includes(nft.meta.categories, 'Access')) &&
                                            <div className='type'><FontAwesomeIcon icon={faKey} /></div>
                                        }
                                        { (_.includes(nft.meta.categories, 'Reward')) &&
                                            <div className='type'><FontAwesomeIcon icon={faTrophy} /></div>
                                        }
                                        { (_.includes(nft.meta.categories, 'Role')) &&
                                            <div className='type'><FontAwesomeIcon icon={faUserTie} /></div>
                                        }
                                    <Category key={`${nft.id}-${category.toLowerCase()}`}>{category}</Category>
                                    </>
                                ))}
                            </div>
                        </div>
                    </NFT>
                ))}
                </>
            }

        </Gallery>
    </Content>
  );
};

export default NFTGallery;


//${DropShadow()}
const NFT = styled.div `
    position: relative;
    padding-top: 15px;

    border: 1px solid #e3e3e3;

    .cover {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .9);
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: auto;
        text-align: center;
        opacity: 0;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        cursor: pointer;

        label {
            font-size: 12px;
            text-transform: uppercase;

        }

        div.line {
            height: 1px;
            background: #aaa;
            width: 60%;
            margin: 15px auto;
        }

        > div, label, h4 {
            margin-bottom: 5px;
            cursor: pointer;
        }

        h4, h5 {
            text-align: center;
        }
    }
    &:hover {
        .cover {
            opacity: 1;
        }
        .claimedOut {
            width: 70px !important;
        }
    }
    .claimed {
        position: absolute;
        top: 15px;
        right: 20px;
        overflow: hidden;
        display: flex;
        align-items: center;
        
        svg {
            font-size: 1.25em;
            color: ${props => props.theme.color.ice};
        }
        z-index: 10;
        .claimedOut {
            display: inline-block;
            overflow: hidden;
            width: 0px;
            -webkit-transition: width .3s ease-in-out;
            -moz-transition: width .3s ease-in-out;
            -o-transition: width .3s ease-in-out;
            transition: width .3s ease-in-out;
        }
        .claimedInn {
            padding-left: 10px;
            width: 65px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
            color: ${props => props.theme.buttons.primary.color};
        }
    }
`


const Details = styled.div `
    position: absolute;
    top: 15px;
    left: 15px;
    display: flex;
    align-items: center;
    div.avatar {
        margin-right: 7px;
    }
    svg {
        color: #fff;
    }
    div.type {
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        border-radius: 50%;
        background: #24a9a3;
        svg {
            font-size: 13px;
        }
    }
`

const Type = styled.div `
    position: absolute;
    top: 15px;
    left: 15px;
`

const Claimed = styled.div `

`

const Name = styled.div `
    text-align: center;
    font-size: 16px;
`

const Category = styled.div `

`

const Circle = styled.div `
    display: flex;
    align-times: center;

    div.avatar {
        display: inline-block;
        margin-right: 5px;
    }    


`

const Top = styled.div `
    display: flex;
    justify-content: space-between;
`

const TopLeft = styled.div `

`

const TopRight = styled.div `
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: right;

    label {
        margin-right: 10px;
        text-transform: uppercase;
        font-size: 12px;
    }
    ${MobileStyles(`
        label {
            display: none;
        }
    `)};
`

const Filters = styled.div `
    text-align: right;
    ${MobileStyles(`
        text-align: left;
    `)};
`

interface StyledFilterProps {
    active: boolean
}

const Filter = styled.span <StyledFilterProps> `
    padding: 8px 12px;
    cursor: pointer;

    opacity: ${({active}) => {
        if ( active === true ) {
          return 1;
        }
        if ( active === false ) {
          return .75
        }
    }};

    svg {
        margin-right: 10px;     
    }

    margin-right: 1px;

    &:last-child {
        margin-right: 0px;
        -webkit-border-top-right-radius: 10px;
        -webkit-border-bottom-right-radius: 10px;
        -moz-border-radius-topright: 10px;
        -moz-border-radius-bottomright: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    &:first-child {
        -webkit-border-top-left-radius: 10px;
        -webkit-border-bottom-left-radius: 10px;
        -moz-border-radius-topleft: 10px;
        -moz-border-radius-bottomleft: 10px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    ${MobileStyles(`
        display: inline-block;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        margin-right: 5px;
        margin-bottom: 5px;
    `)};

    &.category {
        text-transform: uppercase;
        background: ${props => props.theme.botBar.background};
        color: #000;
    }

    &:not(.category) {
        background: ${({active, theme}) => {
            if ( active === true ) {
                return theme.color.ice;        
            }
            if ( active === false ) {
                return theme.botBar.background;
            }
        }};
    }

    color: ${({active, theme}) => {
        if ( active === true ) {
            return theme.buttons.primary.color;        
        }
        if ( active === false ) {
            return 'inherit';
        }
    }};
`

const Content = styled.div `

`

// padding: 1rem;
const Gallery = styled.div `
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 1rem;

    > div {
        position: relative;
        cursor: pointer;
        background-color: ${props => props.theme.card.background};
        overflow:hidden;
        display: grid;
        place-items: center;
        ${BorderRadius()};
        &::before {
            // for apsect ratio
            content: "";
            display: block;
            padding-bottom: 100%;
            grid-area: 1 / 1 / 2 / 2;
        }
        img {
            width: 100%;
            // height: 100%;
            // object-fit: contain;
            grid-area: 1 / 1 / 2 / 2;
        }
    }
`
