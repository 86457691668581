import React from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";

import farmer from '../../assets/img/farmer.png';
import icewaterLogo from '../../assets/img/icewater_logo_color.svg';
import StyledLink from 'components/Link'

interface Props {

}

const Loading: React.FC<Props> = ({

}) => {
    return (
    <Content>  
        Loading ... 
    </Content>
    )
};

const Content = styled.div`
    padding: 50px 0px;
    text-align: center;
`;

export default Loading;
