import React, { createContext, useState, useCallback, useContext } from "react";
import { AnyObject } from "immer/dist/internal";
import { ThemeProvider as StyledThemeProvider, createGlobalStyle } from "styled-components";
import themeProps from './themes'

export interface ThemeContextProps {
    theme: string,
    changeTheme: (theme:string) => void
}

export const ThemeContext = createContext<ThemeContextProps>({ 
    theme: 'light',
    changeTheme: () => {},
})

export const ThemeStore: React.FC = ({ children }) => {
    const [theme, setTheme] = useState<string>('light')

    const onChangeTheme = useCallback((theme:string) => {
        setTheme(theme)
    }, [setTheme])

    return <ThemeContext.Provider value={{ 
        changeTheme: onChangeTheme, 
        theme
    }}>{children}</ThemeContext.Provider>;
}

export const themes:AnyObject = themeProps

const GlobalStyle = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}
`;

export const ThemeProvider:React.FC = ({ children }) => {
    const { theme } = useContext(ThemeContext);
    return (
        <StyledThemeProvider theme={themes[theme]}>
            <>
                <GlobalStyle />
                {children}
            </>
        </StyledThemeProvider>
    );
};