import React from 'react'
import styled from 'styled-components'
//const tintShadeColor = require('tint-shade-color');

/* 
Hue: Pure Colors
Tints: Hue + White
Tones: Hue + Grey
Shades: Hue + Black
*/

const themeProps = {
    light: {
        global: {
            fontFamily: "'Inter', sans-serif",
            padding: 30,
            borderRadius: 20,
            borderColor: '#d6d6d6',
            shadowColor: '#131315',
            positiveColor: '#34d04e', // red
            negativeColor: '#d0344a' // green
        },
        color: {
            h2o: '#24a9a3',
            ice: '#0191c7',
            steam: '#a54381',
        },
        page: {
            background: '#eee',
            maxWidth: 1200
        },
        modal: {
            background: '#ddd',
            maxWidth: 600,
            input: {
                background: '#eee'
            }
        },
        input: {
            background: '#fff',
            borderColor: '#ccc',
            select: {
                background: '#ddd',
                borderColor: '#ccc',
                option: {
                    backgroundHover: '#24a9a3',
                    backgroundFocus: '#0191c7'
                }
            }
        },
        topBar: {
            background: '#fff',
            height: 60
        },
        botBar: {
            background: '#ddd', //'#1a1b1f',
            height: 60
        },
        nav: {
            background: '#fff',
            backgroundHover: '#ccc',
            color: '#333',
            icon: {
                background: '#fff',
            },
            active: {
                highlight: '#cf4691',
                background: '#2C2E39'
            }
        },
        card: {
            background: '#fff',
            borderColor: '#d6d6d6',
        },
        table: {
            borderColor: '#d6d6d6',
            evenRowBackground: '#202128',
        },
        buttons: {
            primary: {
                background: '#0191c7',
                backgroundHover: '#019ed9',
                color: '#fff',
                colorHover: '#ffffff'
            },
            secondary: {
                background: '#0191c7',
                backgroundHover: '#0191c7',
                color: '#fff',
                colorHover: '#F7FCFE' 
            },
            tertiary: {
                background: '#eee',
                backgroundHover: '#eee',
                color: '#333'
            }
        },
        text: {
            primary: {
                color: '#333',
                hoverColor: '#222',
                highlightColor: '#222',
            },
            secondary: {
                color: '#ADADB2',
                hoverColor: '#C2C2C5',
                highlightColor: '#C2C2C5',
            },
            highlight: {
                1: {
                    color: '#cf4691',
                    hoverColor: '#e64da1',
                }
            },
            header: {
                color: '#f1d5e4'
            }
        },
        spacing: {
            1: 4,
            2: 8,
            3: 16,
            4: 24,
            5: 32,
            6: 48,
            7: 64,
        }
    },
    dark: {
        global: {
            fontFamily: "'Inter', sans-serif",
            padding: 30,
            borderRadius: 20,
            borderColor: '#393c53',
            shadowColor: '#131315',
            positiveColor: '#34d04e', // red
            negativeColor: '#d0344a' // green
        },
        page: {
            background: '#202128',
            maxWidth: 1200
        },
        modal: {
            background: '#2A2B37',
            maxWidth: 600,
            input: {
                background: '#454650'
            }
        },
        input: {
            background: '#454650',
            borderColor: '#5C5D66',
            select: {
                background: '#454650',
                borderColor: '#5C5D66',
                option: {
                    backgroundHover: '#20A9E4',
                    backgroundFocus: '#5C5D66'
                }
            }
        },
        topBar: {
            background: '#202128', //'#1a1b1f',
            height: 60
        },
        botBar: {
            background: '#202128', //'#1a1b1f',
            height: 60
        },
        nav: {
            background: '#2a2a2a',
            active: {
                highlight: '#cf4691',
                background: '#2C2E39'
            }
        },
        card: {
            background: '#1a1b1f',
            borderColor: '#3a3c4a',
        },
        table: {
            borderColor: '#3a3c4a',
            evenRowBackground: '#202128',
        },
        buttons: {
            primary: {
                background: '#cf4691',
                backgroundHover: '#e64da1',
                color: '#f1d5e4',
                colorHover: '#ffffff'
            },
            secondary: {
                background: '#009de0',
                backgroundHover: '#20A9E4',
                color: '#f1d5e4',
                colorHover: '#F7FCFE' 
            },
            tertiary: {
                background: '#454650',
                backgroundHover: '#5C5D66',
                color: '#E8E8E9'
            }
        },
        text: {
            primary: {
                color: '#E3E3E4',
                hoverColor: '#F8F8F8',
                highlightColor: '#F8F8F8',
            },
            secondary: {
                color: '#ADADB2',
                hoverColor: '#C2C2C5',
                highlightColor: '#C2C2C5',
            },
            highlight: {
                1: {
                    color: '#cf4691',
                    hoverColor: '#e64da1',
                }
            },
            header: {
                color: '#f1d5e4'
            }
        },
        spacing: {
            1: 4,
            2: 8,
            3: 16,
            4: 24,
            5: 32,
            6: 48,
            7: 64,
        }
    }
}


export default themeProps