import React, { useContext } from 'react'
//import styled, { ThemeContext } from 'styled-components'
import styled from 'styled-components'
import { ThemeContext, ThemeContextProps, themes } from 'contexts/Theme/Theme';


interface SpacerProps {
  size?: 'xs' | 'sm' | 'md' | 'lg',
  direction?: 'hz' | 'vt'
}

const Spacer: React.FC<SpacerProps> = ({ size = 'md', direction = 'vt' }) => {
  //const { spacing } = useContext(ThemeContext)

  const { theme } = useContext(ThemeContext) as ThemeContextProps;
  
  let s: number
  switch (size) {
    case 'lg':
      s = themes[theme].spacing[6]
      break
    case 'sm':
      s = themes[theme].spacing[2]
      break
    case 'xs':
    s = themes[theme].spacing[2] * .5
    break
    case 'md':
    default:
      s = themes[theme].spacing[4]
  }
  
  if ( direction === 'vt' ) {
    return (
      <StyledSpacerVertical size={s} />
    )
  }

  return (
    <StyledSpacer size={s} />
  )
}

interface StyledSpacerProps {
  size: number,
}

const StyledSpacer = styled.div<StyledSpacerProps>`
  height: ${props => props.size}px;
  width: ${props => props.size}px;
`

const StyledSpacerVertical = styled.div<StyledSpacerProps>`
  display: inline-block;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
`

export default Spacer