import Config from '../config'
import { stringToURLSlug } from '../utils/utils'
import { DateTime } from "luxon";

var _ = require('lodash');
const token = '5762|p69JYICcytRJfVPhCi0Ae9vtBRHBjUVAKAj9emrZ'
const API = "https://coordinape-prod.hasura.app/v1/graphql"
const ImageServer = "https://coordinape-prod.s3.amazonaws.com"

// This is the API address that we don't want to include in our list
const OmitAddresses = [
    "0x479c547309def2471a8230cc863a559d0ef2688f",
    "0xfad763da9051953fea58c2304395719a3b6ba361"
]

const ProfileAttrs = `
    avatar
    bio
    website
    skills
    address
    background
    users {
        name
    }
`

async function fetchGraphQL(operationsDoc:any, operationName:any, variables:any) {
    const result = await fetch(
      API,
      {
        method: "POST",
        headers: { 
            'Authorization': `Bearer ${token}` 
        },
        body: JSON.stringify({
            query: operationsDoc,
            variables: variables,
            operationName: operationName
        })
      }
    );
  
    return await result.json();
}


export const getCoordinapeUser = async function(address:string) { 
    const users = await getCoordinapeUsers([address] as any, null)
    if ( users ) {
        return users[0]
    }
    return null
}

// https://cloud.hasura.io/public/graphiql?endpoint=https%3A%2F%2Fcoordinape-prod.hasura.app%2Fv1%2Fgraphql&header=Authorization%3ABearer+16918%7CNN6U9Y79WEwqZG65YTKOsaGbnOdt9vNehyJuzkYZ&query=query+MyQuery+%7B%0A++profiles%28where%3A+%7Baddress%3A+%7B_eq%3A+%220x26fd9e512a351aee64329f2b2bd1a6567e6f6154%22%7D%7D%29+%7B%0A++++id%0A++++avatar%0A++++bio%0A++++website%0A++++skills%0A++++address%0A++++background%0A++%7D%0A%7D%0A
export const getCoordinapeUsers = async function(ids:[] | null = null, circleIds:[] | null = null) { 
    var filterByAddress = ''
    if ( ids ) {
        filterByAddress = `address: {
            _in: ["${_.join(ids, '","')}"]
        },`
    }

    var filterByCircleId = ''
    if ( circleIds ) {
        filterByCircleId = `circle_id: {
            _in: ["${_.join(circleIds, '","')}"]
        },`
    }

    var filter = `(where: {
        ${filterByAddress}
        ${filterByCircleId}
        _not: {address: {_in: ["${_.join(OmitAddresses, '","')}"]}}
    })`

    const { errors, data } = await fetchGraphQL(
        `query AllUsers {
            users ${filter} {
                id
                profile {
                    ${ProfileAttrs}
                }
                role
                name
                address
                bio
                received_gifts_aggregate {
                    aggregate {
                      sum {
                        tokens
                      }
                    }
                }
                circle {
                    id
                    name
                  }
              }
              
        }
        `,
        "AllUsers",
        {}
    )
    if ( errors ) {
        return errors
    }

    var users: any = {}
    if ( data.users ) {
        // Create a unqiue users object
        for (var user of data.users) {
            if ( !_.has(users, user.address) ) {
                users[user.address] = {
                    address: user.address,
                    profile: standardizeProfile(user.profile),
                    tokens: 0,
                    circles: []
                }
            }
        }

        // Append user circles to users object
        for (var user of data.users) {
            var circle = user.circle
            var tokens = user.received_gifts_aggregate.aggregate.sum.tokens
            circle.tokens = tokens
            circle.bio = user.bio
            circle.role = user.role
            users[user.address]['circles'].push(circle)
            users[user.address]['tokens'] += tokens
        }
    }
    return _.values(users)
}

export const getCoordinapeUsersByCircle = async function(id:number) { 
    const users = await getCoordinapeUsers(null, [id] as any)
    if ( users ) {
        return users
    }
    return null
}

export const getCoordinapeSkill = async function(skill:string) { 
    const skills = await getCoordinapeSkills() 
    return _.find(skills, ['id', skill]);
}

export const getCoordinapeSkills = async function() { 
    const users = await getCoordinapeUsers()
    
    var skillStrings:any = []
    for (var user of users) {
        if ( user.profile.skills ) {
            skillStrings = skillStrings.concat(user.profile.skills)
        }
    }

    var data: any = {}
    for (var skill of _.uniq(skillStrings)) {
        var key = stringToURLSlug(skill)
        var skillUsers:any = []
        var skillCircles:any = []

        for (var user of users) {
            if ( user.profile.skills ) {
                if ( _.includes(user.profile.skills, skill) ) {
                    skillUsers.push(user)
                }
            }
            if ( user.circles ) {
                skillCircles = skillCircles.concat(user.circles)
            }
        }

        data[key] = {
            id: key,
            name: skill,
            users: skillUsers,
            circles: _.uniqBy(skillCircles,'id')
        }
    }

    return _.values(data)
}

export const getCoordinapeSkillsByCircle = async function(id:number) { 
    const circles = await getCoordinapeSkillsByCircles([id] as any)
    if ( circles.length ) {
        return circles[0]
    }
    return null
}

// returns all skills from all users
export const getCoordinapeSkillsByCircles = async function(ids:[] | null = null) { 
    const { errors, data } = await fetchGraphQL(
        `query CommunitySkills {
            circles {
                name
                id
                users {
                  profile {
                    address
                    skills
                  }
                }
            }
        }
        `,
        "CommunitySkills",
        {}
    )
    if ( errors ) {
        return errors
    }

    var circles: any = {}
    if ( data.circles ) {

        for (var circle of data.circles) {
            circles[circle.id] = {
                users: [],
                skills: []
            }

            for (var user of circle.users) {
                if ( user.profile.skills ) {
                    var userSkills = stringToArray(user.profile.skills)
                    circles[circle.id].users.push({
                        ...user.profile,
                        skills: userSkills
                    })
                    circles[circle.id].skills = circles[circle.id].skills.concat(userSkills)
                }
            }

            circles[circle.id].skills = _.uniq(circles[circle.id].skills)  
        }
    }
    //console.log('circles', circles)
    return _.values(circles)
}


export const getCoordinapeCircle = async function(id:number) { 
    const circles = await getCoordinapeCircles([id] as any)

    if ( circles.length ) {
        return circles[0]
    }
    return null
}

// epochs(where: {ended: {_eq: false}}) {
export const getCoordinapeCircles = async function(ids:[] | null = null) { 
    const { errors, data } = await fetchGraphQL(
        `query AllCircles {
            circles {
                id
                name
                logo
                team_sel_text
                organization {
                    name
                }
                epochs(limit: 5, order_by: {number: desc}) {
                    id
                    end_date
                    start_date
                    number
                }
            }
        }
        `,
        "AllCircles",
        {}
    )
    if ( errors ) {
        return errors
    }

    if ( data.circles ) {
        var circles: any[] = []
        for (var circle of data.circles ) {

            if ( circle.logo ) {
                circle.logo = `${ImageServer}/${circle.logo}` 
            }

            if ( circle.epochs ) {
                circle.epochs = standardizeEpochs(circle.epochs)
            }

            if ( ids ) {
                if ( _.includes(ids, circle.id) ) {    
                   circles.push(circle) 
                }
            } else {
                circles.push(circle)
            }
        }

        return circles
    }
    return []
}


export const getCoordinapeUserTokenHistory = async function(ids:[] | null = null) { 
    var isolate = ''
    if ( ids ) {
        isolate = `(where: {
            recipient_address: {
                _in: ["${_.join(ids, '","')}"]
            }
        })`
    }

    const { errors, data } = await fetchGraphQL(
        `query UserTokens {
            token_gifts ${isolate} {
                recipient_address
                tokens
                sender {
                  address
                  name
                }
                dts_created
            }
        }
        `,
        "UserTokens",
        {}
    )
    if ( errors ) {
        return errors
    }

    var users: any = {}
    if ( data.token_gifts ) {
        for (var gift of data.token_gifts) {
            users[gift.recipient_address] = {
                tokens: 0,
                gifts: []
            }
        }

        for (var gift of data.token_gifts) {
            users[gift.recipient_address].tokens += gift.tokens
            users[gift.recipient_address].gifts.push(gift)
        }
    }
    return users
}




function standardizeEpochs(epochs:any) {
    var standardized: any[] = []
    for (var epoch of epochs) {
        standardized.push(standardizeEpoch(epoch))
    }
    return standardized
}

// 2022-05-04T00:00:00+00:00
function standardizeEpoch(epoch:any) {
    // active
    if ( (DateTime.now() > DateTime.fromISO(epoch.start_date)) && 
         (DateTime.now() < DateTime.fromISO(epoch.end_date)) ) {
        epoch['active'] = true
    } else {
        epoch['active'] = false
    }

    // past
    if ( (DateTime.now() > DateTime.fromISO(epoch.start_date)) ) {
        epoch['past'] = true
    } else {
        epoch['past'] = false
    }

    // future
    if ( (DateTime.now() < DateTime.fromISO(epoch.start_date)) ) {
        epoch['future'] = true
    } else {
        epoch['future'] = false
    }

    epoch['now_relative'] = DateTime.now().toRelative()
    epoch['start_relative'] = DateTime.fromISO(epoch.start_date).toRelative()
    epoch['end_relative'] = DateTime.fromISO(epoch.end_date).toRelative()

    return epoch
}

function standardizeProfile(profile:any ) {
    var user = profile
    if ( user ) {
        if ( user.avatar ) {
            user.avatar = `${ImageServer}/${user.avatar}`      
        }

        if ( user.skills ) {
            user.skills = stringToArray(user.skills)
        } else {
            user.skills = []
        }

        if ( user.users ) {
            user.name = user.users[0]['name']
        }
        delete user.users
    }
    return user
}

function stringToArray(text:string) {
    var result = text.replace(/\n|\r/g, "")
    result = result.replace(/"/g, '')
    result = result.replace(/\[/g, '')
    result = result.replace(/\]/g, '')
    return _.split(result, ',')
}
