import { css } from 'styled-components'

// https://dev.to/yuschick/logical-css-with-simple-styled-components-mixins-21i8
// import { Transition } from '../../contexts/Theme/Mixins'

export const BorderRadius = (radius:number=20) => css`
    -webkit-border-radius: ${radius}px;
    -moz-border-radius: ${radius}px;
    border-radius: ${radius}px;
`;

export const Transition = (property:string, duration:string='.3s', fn:string='linear' ) => css`
    transition-property: ${property};
    transition-duration: ${duration};
    transition-timing-function: ${fn};
`;

export const DropShadow = () => css`
    box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.3);
`;

export const GlassBackground = () => css`
    //backdrop-filter: blur(5px); 
    background-color: rgba(255, 255, 255, 0.8);
    ${DropShadow()}
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-left: 1px solid rgba(255, 255, 255, 0.2);
`;


/*
${MobileStyles(`

`)};
*/
export const MobileStyles = (style:any, maxWidth:number=767) => css`   
@media only screen and (max-width: ${maxWidth}px) {
    ${style}
}
`;

