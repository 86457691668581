import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Avatar from '../../components/Avatar';
import Link from '../../components/Link';
import { stringToURLSlug } from '../../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdjust, faBullseye, faCircle, faHandsHelping } from '@fortawesome/free-solid-svg-icons';
import { Transition, BorderRadius } from '../../contexts/Theme/Mixins'

import bg from '../../assets/watercolor/iw-community.png';

import { getCoordinapeCircles, getCoordinapeUsers  } from 'ice-water/Coordinape';


import EpochStatus from '../../views/Circle/Components/EpochStatus';


interface SkillsProps {
    ids: []
}

const Circles: React.FC<SkillsProps> = ({
    ids
}) => { 
    const [circles, setCircles] = useState<any>()

    useEffect(() => { 
        fetchData()
    }, [])
    
    const fetchData = async (): Promise<void> => {
        const circlesData = await getCoordinapeCircles(ids)
        //console.log(circlesData)
        setCircles(circlesData)
    }

    return (
    <Content>  
        {circles &&
            <>
            { circles.map((circle:any, index:number) => (
                <Link key={index} to={`/circle/${circle.id}`}>
                    <Left className='left'>
                        <Logo>
                            <Avatar img={circle.logo}
                                icon={<FontAwesomeIcon icon={faAdjust} />}
                            />                     
                        </Logo>
                    </Left>
                    <Right className='right'>
                        <Name>{ circle.name }</Name>
                        <Epoch>
                            { circle.epochs.length ? (
                                <EpochStatus epoch={circle.epochs[0]} />
                            ): (
                                <>-</>
                            )}
                        </Epoch>
                    </Right>
                    
                </Link>
            ))}
            </>
        }
    </Content>
  );
};

export default Circles;

const Content = styled.div `
    max-width: 100%;

    > div a {
        padding: 5px 10px;

        ${BorderRadius()}
        &:hover {
            background: #eee;
            .avatar {
                background: #ddd;
            }
        }
        
        display: flex;

        .left, .right {
            display: block;
        }

        .left {
            width: 50px;
            margin-right: 10px;
        }
        .right {
            padding-top: 5px;
            flex: 1;
        }
    }
`

const Logo = styled.span `
    cursor: pointer;
    
`

const Left = styled.span `

`

const Right = styled.span `
    
`

const Epoch = styled.span `
    
`


const Name = styled.span `
    display: block;
    cursor: pointer;
    font-weight: bold;
`

/*
    &:hover {
        background-color: ${props => props.theme.page.background};
        background: url("${bg}") no-repeat top left;
        background-position: top -150px left 0px;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        label {
            color: #fff !important;
            font-weight: bold;
        }
    }

    &:hover {
        background: ${props => props.theme.buttons.primary.background};
        label {
            color: #fff !important;
        }
    }
*/
