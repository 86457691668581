import React, { useCallback, useEffect, useState, useContext, useRef, createRef } from 'react';
// import { useHistory } from 'react-router-dom';
// import { History } from 'history';
// import { createBrowserHistory } from 'history';

import styled from 'styled-components';
import Button from '../../components/Button';
import Modal, { ModalProps } from '../../components/Modal';
import ModalActions from '../../components/ModalActions';
import ModalTitle from '../../components/ModalTitle';
import Input from '../../components/Form/Input';
import Textarea from '../../components/Form/Textarea';
//import { getFunctions, httpsCallable } from "firebase/functions";

import { ThemeContext, ThemeContextProps, themes } from '../../contexts/Theme/Theme';
import { AccountContext } from '../../contexts/Account/AccountContext';
import { BorderRadius } from '../../contexts/Theme/Mixins'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Spacer from '../../components/Spacer';
import { useForm } from "react-hook-form";


var _ = require('lodash');


interface NFTModalProps extends ModalProps {
    nft: any,
    onConfirm: () => void,
    onDismiss: () => void
}


const NFTModal: React.FC<NFTModalProps> = ({
    nft,
    onConfirm,
    onDismiss }) => {

    //const { accountUser, accountData } = useContext(AccountContext); 

    const { theme } = useContext(ThemeContext) as ThemeContextProps;
    const handleDismiss = useCallback(() => {
        console.log("dismiss?")
        onDismiss()
        onConfirm()
    }, [onDismiss]);

    
    console.log(nft)

    console.log("HERE")

    return (
    <Modal >
        {/* Collection Picker */}
        <ModalTitle text={nft.name} />
        <Circle>{nft.parent.name}</Circle>
        <Spacer size='sm' />

        <NFTInfo>
            {nft.info}
        </NFTInfo>
        <Spacer size='md' />

        { nft.claimedTimes ? (
            <NFTImage>
                <Claimed>
                    <Button size='sm'>
                        <FontAwesomeIcon icon={faCheck} /> Claimed
                    </Button>
                </Claimed>
                <img src={nft.thumbnail} />
            </NFTImage>
        ): (
            <NFTImage>
                <img src={nft.thumbnail} />
            </NFTImage>
        )}

        <ModalActions>
            <Button size='md' text="Close" background='tertiary' onClick={handleDismiss} />       
        </ModalActions>

    </Modal>
    )
}

const NFTInfo = styled.div `

`

const Circle = styled.div `
    text-transform: uppercase;
    text-align: center;
    opacity: .5;
    font-weight: bold;
    position: relative;
    top: -10px;
`

const NFTImage = styled.div `
    position: relative;
    ${BorderRadius()};
    overflow: hidden;
    img {
        max-width: 100%;

    }

    -webkit-box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.15); 
    box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.15);
    background-color: ${props => props.theme.card.background};
`

const Claimed = styled.div `
    position: absolute;
    top: 10px;
    right: 10px;
    button {
        font-size: 16px;
    }
`

export default NFTModal