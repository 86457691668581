import React, { createContext, useMemo, useContext, useEffect, useState, useCallback } from 'react';
import { signInWithCustomToken, signOut, onAuthStateChanged } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { ref, update} from "firebase/database";
import { auth, functions, database } from '../../firebase';
import Config from 'config';
import { WalletContext } from './WalletContext';
import useInterval from 'hooks/useInterval';

export type AccountContextType = {
    accountUser: UserProps | null;
}

export type UserProps = {
    backgroundImg: string;
    description: string;
    discord: string;
    github: string;
    lastLogin: string;
    medium: string;
    name: string;
    optInShare: string;
    profileImg: string;
    telegram: string;
    twitter: string;
    uid: string;
    website: string;
    avatar: string;
}

const AccountContext = createContext<AccountContextType>({ 
    accountUser: null
})

const AccountProvider: React.FC = ({ children }) => {
    const getAuthToken = httpsCallable(functions, 'getAuthToken')
    const getUser = httpsCallable(functions, 'getUser')
    const { address, isOnChain } = useContext(WalletContext)
    const [accountUser, setAccountUser] = useState<any | null>(null); 

    /*
    // How to update something on a refreshInterval
    useInterval(() => {
        
    }, isOnChain ? Config.refreshInterval : null)
    */

    useEffect(() => {
        if ( address ) {
            getAccountUser()
        } else {
            reset()
        }
    }, [address])

    const reset = () => {
        setAccountUser(null)
    }

    const getAccountUser = async () => {
        if ( !address ) return

        // Update the lastLogin info
        update(ref(database, `users/${address}`), {
            uid: address,
            lastLogin: Date.now()
        })

        getAuthToken({ 
            address: address
        }).then((result) => {
            const data:any = result.data        

            signInWithCustomToken(auth, data.token)
                .then((userCredential) => {
                    getUser({
                        id: address
                    }).then((result) => {
                        setAccountUser(result.data)
                    })
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                });
        })
    }

    return  (
        <AccountContext.Provider value={{ accountUser }}>
            {children}
        </AccountContext.Provider>
    );
};
export  { AccountContext,  AccountProvider };
