import { forEach } from 'lodash';
import Config from '../config'
//var fetch = require('node-fetch');
var _ = require('lodash');

const API = 'https://graphigo.prd.galaxy.eco'
//const API = 'https://graphigo.stg.galaxy.eco/query'

/*
    // https://docs.galaxy.eco/API--Integration/rya9Z7x2rhXmdbmkzKq2EM

    Best way to generate the fetch query:
    
    1. Use galaxy's graph bin to build a query.
        https://graphqlbin.com/v2/2DZ9FV
    2. Copy the CURL (button top right)
    3. Convert the CURL to a node fetch query
        https://curlconverter.com/#node-fetch
    4. Copy the body attr
        see getNFTinfo() function
*/

const Headers = {
    'Accept-Encoding': 'gzip, deflate, br',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Connection': 'keep-alive',
    'DNT': '1',
    'Origin': 'https://community.icewater.money'
}

export const getCampaigns = async (address:string = '') => {
    var body = JSON.stringify({"query":"{\n  dao(alias: \"icewater\") {\n    campaigns(first: 10) {\n      list {\n        id\n        numberID\n        name\n        gamification {\n          type\n        }\n        childrenCampaigns {\n          id\n          numberID\n          name\n          info\n          description\n          numNFTMinted\n          requirementInfo\n          status\n          thumbnail\n          createdAt\n          holders {\n        \t\tlist {\n          \t\taddress\n        \t\t}\n      \t\t}\n          gamification {\n            type\n          }\n        }\n      }\n    }\n  }\n}\n"})
    if ( address ) {
        body = JSON.stringify({"query":`{\n  dao(alias: \"icewater\") {\n    campaigns(first: 10) {\n      list {\n        id\n        numberID\n        name\n        gamification {\n          type\n        }\n        childrenCampaigns {\n          id\n          numberID\n          name\n          info\n          description\n          numNFTMinted\n          requirementInfo\n          status\n          thumbnail\n          createdAt\n          claimedTimes(address: \"${address}\")\n          holders {\n        \t\tlist {\n          \t\taddress\n        \t\t}\n      \t\t}\n          gamification {\n            type\n          }\n        }\n      }\n    }\n  }\n}\n`})
    }

    let response = await fetch(`${API}/query`, {
        headers: Headers,
        body: body,
        method: "POST"
    });
    const data = await response.json()
    var campaigns: any[] = []
    //console.log(data)

    for (const campaign of data.data.dao.campaigns.list) {
        if ( campaign.childrenCampaigns ) {
            for (const childCampgain of campaign.childrenCampaigns) {

                var c:any = {
                    ...childCampgain,
                    parent: {
                        id: campaign.id,
                        name: campaign.name,
                        number: campaign.numberID
                    }, 
                    meta: {
                        categories: [],
                        circle: {
                            id: 655
                        }
                    }
                }

                var cat = []
                if ( c['name'] === 'The Gibbon' ) {
                    cat.push('Association')
                }
                if ( c['name'] === 'The Frosty Ape' ) {
                    cat.push('Access')
                }
                if ( c['name'] === 'The Crown' ) {
                    cat.push('Reward')
                }
                if ( c['name'] === 'The Warm Frosty' ) {
                    cat.push('Reward')
                }
                c['meta']['categories'] = cat

                campaigns.push(c)       
            } 
        }
    }
    return campaigns
}

export const getUserRewards = async (address:string) => {
    const campaigns = await getCampaigns(address)
    var userCampaigns: any[] = []
    for (const campaign of campaigns) {
        if ( campaign.claimedTimes > 0 ) {
            userCampaigns.push(campaign)
        }
    }
    return userCampaigns
}

// export const getCampaignsByIds = async (ids:string[], address:string = '') => {
//    const campaigns = await(getCampaigns(address))
//     console.log(campaigns)
// }



// Get's NFT image, campaign info (name, info, etc)
export const getNFTinfo = async (id:string, address:string) => {
    let response = await fetch(`${API}/query`, {
        headers: Headers,
        body: JSON.stringify({"query":`query nftMetadata {\n  nftInfo(id: ${id}, nftCoreAddress: \"${address}\") {\n    id\n    nftCore {\n      name\n    }\n    campaign {\n      name\n      id\n      info\n      holders {\n        list {\n          address\n        }\n      }\n    }\n    chain\n    name\n    image\n    ipfsImage\n    category\n    description\n    owner {\n      address\n    }\n    status\n    createdAt\n    createBlock\n    animationURL\n    traits {\n      displayType\n      name\n      value\n    }\n  }\n}\n`}),
        method: "POST"
    });
    const data = await response.json()
    var info = data.data.nftInfo
    info.holderAddresses = _.map(info.campaign.holders.list, 'address'); 
    return info
}


// Get's a list of addresses who hold the given NFT
export const getNFTholders = async (nft_campaign_id:string) => {
    let response = await fetch(`${API}/query`, {
        headers: Headers,
        body: JSON.stringify({"query":`query nftHolders{\n     campaign(id: \"${nft_campaign_id}\") {\n         holders(first: 1000 after: \"\") {\n             totalCount\n             pageInfo {\n                 startCursor\n                 endCursor\n                 hasNextPage\n                 hasPreviousPage\n             }\n             list {\n                 address\n             }\n         }\n     }\n }`}),
        method: "POST"
    });
    const data = await response.json()
    var holders = {
        ...data.data.campaign.holders
    }
    holders.addresses = _.map(holders.list, 'address');
    return holders
}



