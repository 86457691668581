import React from 'react'
import styled from 'styled-components'
import { Link, NavLink } from 'react-router-dom'

//interface StyledLinkProps extends HTMLAnchorElement {  
interface StyledLinkProps {  
  to?: string,
  href?: string,
  rel?: string,
  text?: string,
  className?: string,
  exact?: boolean,
  activeClassName?: string | null,
  onClick?: () => void,
}

const StyledLink: React.FC<StyledLinkProps> = ({ 
  children, 
  text,
  to,
  href,
  rel='nofollow',
  className,
  exact=true,
  activeClassName=null,

  onClick
}) => {
  return (
    <Content>
    { to &&
        <>
            { exact ? (
                <NavLink to={to} className={className} onClick={onClick} exact >
                    <>
                    {text}
                    {children}
                    </>
                </NavLink> 
            ):(
                <NavLink to={to} className={className} onClick={onClick} >
                    <>
                    {text}
                    {children}
                    </>
                </NavLink>
            )}
        </>      
    }
    {/* { to && activeClassName &&
      <InternalNavLink to={to} className={className} onClick={onClick} >
        {text}
        {children}
      </InternalNavLink>       
    }  */}
    { href && 
      <ExternalLink href={href} target='_blank' rel={rel} className={className}>
        {text}
        {children}
      </ExternalLink>       
    } 
    </Content>     
  )
}

const Content = styled.div `
    display: inline;
    a {
        color: ${props => props.theme.color.white};
        text-decoration: none; 
    }
`

const InternalLink = styled(Link)` 
    color: ${props => props.theme.color.white};
    text-decoration: none;
`

const InternalNavLink = styled(NavLink)` 
    color: ${props => props.theme.color.white};
    text-decoration: none;
`

const ExternalLink = styled.a` 
    color: ${props => props.theme.color.white};
    text-decoration: none;
`

export default StyledLink