//import './NavBar.css';
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';


interface Props {
    menu: React.ReactNode,
    children?: React.ReactNode,
    icon?: React.ReactNode | null,
    to?: string,
    img?: string | null,
    href?: string,
    onClick?: () => void
}
export const NavItem: React.FC<Props> = ({
    menu,
    icon, 
    to, 
    href,
    img,
    children,
    onClick
}) => { 
    const [open, setOpen] = useState(false);

    const handleOnClick = () => {
        if ( !to && !href ) {
            setOpen(!open)
        }
    }

    const id = Date.now().toString()
    const controller = new AbortController();
    useEffect(() => {
        if ( open ) {
            document.addEventListener("click", (e:any) => {
                if ( e.target.id !== id) {
                    setOpen(false)
                }
            }, { signal: controller.signal })
        }
        if ( !open ) {
            controller.abort()
        }

        return function cleanup() {
            controller.abort()
        }
    }, [open])
    

    return (
      <NavItemWrap id={id}>
            <div onClick={handleOnClick} className='nav-item'>
                { children }
            </div>
        { open && 
            menu
        }
      </NavItemWrap>
    );
}


export default NavItem

const NavItemWrap = styled.div `
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .9;
    &:hover {
        opacity: 1;
    }
`