import React, { useState, useEffect, useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import {  } from '@fortawesome/free-regular-svg-icons';
import { CSSTransition } from 'react-transition-group';
import DropdownItem from './DropdownItem';
import { auth } from '../../firebase';
import { signOut } from "firebase/auth";
import { WalletContext } from 'contexts/Account/WalletContext';
import styled from 'styled-components';
var _ = require('lodash');

interface Props {
    children?: React.ReactNode,
    icon?: React.ReactNode
}
export const AccountDropMenu: React.FC<Props> = ({icon, children}) => { 
    const [activeMenu, setActiveMenu] = useState<string>('main');
    const [menuHeight, setMenuHeight] = useState<number>(0);
    //const [chartOptions, setChartOptions] = useState<any>(null)

    const dropdownRef:any = useRef(null);
    const { address, isOnChain, disconnect } = useContext(WalletContext)
  
    useEffect(() => {
        setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
    }, [])
  
    function calcHeight(el:HTMLElement) {
        var height = el.offsetHeight;
        if ( height > window.innerHeight ) {
            height = window.innerHeight - 150
        }
        setMenuHeight(height);
    }    

    const onSignOut = () => {
        signOut(auth).then(() => {
            disconnect()
        }).catch((error) => {
            // An error happened.
        });
    }

    const gotoMenu = (menu:string) => {
        setActiveMenu(menu)
    }
  
    return (
      <div className="dropdown dropdown-account" style={{ height: menuHeight }} ref={dropdownRef}>
        <CSSTransition<undefined>
            in={activeMenu === 'main'}
            timeout={500}
            classNames="menu-primary"
            unmountOnExit
            onEnter={calcHeight}>
            <div className="menu">
                <DropdownItem
                    to={`/profile/${address}`}
                    leftIcon={<FontAwesomeIcon icon={faUser} />}>
                    My Profile
                </DropdownItem>

                <DropdownItem
                    onClick={onSignOut}
                    leftIcon={<FontAwesomeIcon icon={faSignOutAlt} />}>
                    Disconnect / Sign Out
                </DropdownItem>       
            </div>
        </CSSTransition>
      </div>
    );
}

export default AccountDropMenu


const DropdownSeparator = styled.div `
    margin: 15px;
    height: 1px; 
    opacity: .3;
    background: ${(props) => props.theme.color.white};
`
