import React, { useEffect } from 'react';
import styled from 'styled-components';
import { MobileStyles } from '../../contexts/Theme/Mixins'
import Member from './Member';


var _ = require('lodash');

interface MembersProps {
    users: any[],
    show_circles?: boolean
}

const Members: React.FC<MembersProps> = ({
    users,
    show_circles = false
}) => { 
    //users = _.orderBy(users, ['profile.name'], ['asc']);
    users = _.orderBy(users, ['tokens'], ['desc']);

    useEffect(() => { 
        fetchData()
    }, [])

    const fetchData = async (): Promise<void> => {


    }

    return (
    <Content>  
        <Columns>
        { users.map((user:any, index:number) => (
            <Member user={user} key={`member_${index}`}>
                <Xp>
                    { user.tokens == 1 ? (
                        <>{user.tokens} Token</>
                    ) : (
                        <>{user.tokens} Tokens</>
                    )}
                </Xp>

                { show_circles &&
                    <Xp>
                        { user.circles.length == 1 ? (
                            <>{user.circles.length} Circle</>
                        ) : (
                            <>{user.circles.length} Circles</>
                        )}
                    </Xp>
                }
            </Member>
        ))}
        </Columns>
    </Content>
  );
};

export default Members;

const Content = styled.div `
    max-width: 100%;
`

const Xp = styled.div `
    font-size: 14px;
`

const Columns = styled.div `
    column-count: 3;
    column-gap: 40px;
    column-fill: balance;

    @media only screen and (max-width: 825px) {
        column-count: 2;
        column-gap: 0px;
    }

    @media only screen and (max-width: 600px) {
        column-count: 1;
        column-gap: 0px;
    }

    ${MobileStyles(`
        
    `)};
`