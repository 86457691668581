import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons';
import MiddleEllipsis from "react-middle-ellipsis";
import { MobileStyles } from 'contexts/Theme/Mixins';

interface HeaderProps {
    img?: string | null,
    icon?: React.ReactNode,
    size?: number,
    title?: string,
    subtitle?: string
}

const Header: React.FC<HeaderProps> = ({
    img = null,
    icon = <FontAwesomeIcon icon={faUser} />,
    size = 80,
    title,
    subtitle,
    children
}) => { 
    
    const Image = () => {
        return (
            <>
            { img ? (
                <img src={img} />
            ):(
                <>{icon}</>
            )}
            </>
        )
    }

    return (
    <Content>  
        <ImageWrap size={size}>
            <Image />
        </ImageWrap>
        <TitleWrap>
            <h1>{ title }</h1>
            { subtitle &&
                <h3>{ subtitle }</h3>
            }
        </TitleWrap>
    </Content>
  );
};

export default Header;

const Content = styled.div `
    display: flex;
    align-items: center;
    padding: 5px 10px;

    .avatar {
        margin-right: 15px;
    }

    h3 {
        opacity: .7;
    }

    ${MobileStyles(`
        h1 {
            font-size: 20px;
        }
        h3 {
            font-size: 16px;
        }
    `)};
`

interface StyledProps {
    size: number
}

const ImageWrap = styled.div<StyledProps> `
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    overflow: hidden;
    background: #ddd;
    border: 5px solid rgba(255, 255, 255, 0.2);

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 100%;
    }
    svg {
        font-size: ${props => props.size * .5}px;
    }

    ${MobileStyles(`
        width: 40px;
        height: 40px;
        border-width: 2px;
        svg {
            font-size: 24px;
        }
    `)};
`

const TitleWrap = styled.div `
    margin-left: 15px;
    flex: 1;

    ${MobileStyles(`
        overflow: hidden;
    `)};
`

const UserProfile = styled.div `

`
