import React, { useContext, useState } from 'react';
import { slide as Menu } from 'react-burger-menu'
import styled from 'styled-components';
import Logo from '../Logo'
import  { SideMenuItem } from './SideMenuItem'
import  { SideMenuSpacer } from './SideMenuSpacer'
import StyledLink from '../../components/Link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCoins, faExchange, faExchangeAlt, faHome, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faMedium, faMediumM, faTwitter } from '@fortawesome/free-brands-svg-icons';


// Modals
import { NavLink } from 'react-router-dom';
import { SideMenuSection } from './SideMenuSection';
import { WalletContext } from 'contexts/Account/WalletContext';

interface SideMenuProps {
    showLogo?: boolean,
    title?: string,
    isOpen?: boolean,
    children?: React.ReactNode
}
  
const SideMenu: React.FC<SideMenuProps> = ({
    showLogo=true,
    title='',
    isOpen=false,
    children
  }) => { 

    const { address } = useContext(WalletContext)
    
    // TODO: This solution is ghetto.. for some reason setIsOpen(false) is not working
    const closeMenu = () => {
        // setIsOpen(false)
        let element: HTMLElement = document.getElementsByClassName("bm-overlay")[0] as HTMLElement;
        element.click();
    }
  
    return (
        <Content>
            <Menu isOpen={isOpen} 
                  className='side-menu'
                  >
                { showLogo && 
                    <SideMenuSection>
                        <SideMenuItem>
                            <br/>
                            <Logo href='https://community.icewater.money/' />
                        </SideMenuItem>
                    </SideMenuSection>
                }
                { title && 
                    <Title className='bm-item'>{ title }</Title>
                }
                { children && 
                    <>
                        { children }
                        <SideMenuSpacer/>
                    </>
                }

                { address &&
                    <SideMenuSection title='Profile'>
                        <StyledLink to={`/profile/${address}`}>
                            My Profile
                        </StyledLink>
                    </SideMenuSection>
                } 

                <SideMenuSection title='Pages'>
                    <StyledLink to='/'>Community</StyledLink>
                </SideMenuSection>

                <SideMenuSection title='Hydrosphere'>
                <   SideMenuItem 
                        title='Icewater' 
                        href='https://icewater.money'
                        icon={<FontAwesomeIcon icon={faHome} />} />
                    <SideMenuItem 
                        title='Trade Tokens' 
                        href='https://app.icewater.money'
                        icon={<FontAwesomeIcon icon={faExchange} />} />
                    <SideMenuItem 
                        title='Discord' 
                        href='https://discord.gg/yBqPTXbpEz'
                        icon={<FontAwesomeIcon icon={faDiscord} />} />
                    <SideMenuItem 
                        title='Medium' 
                        href='https://medium.com/icewatermoney'
                        icon={<FontAwesomeIcon icon={faMediumM} />} />
                    <SideMenuItem 
                        title='Twitter' 
                        href='https://twitter.com/icewatermoney'
                        icon={<FontAwesomeIcon icon={faTwitter} />} />
                </SideMenuSection>
        </Menu>
      </Content>
    )
}

const Content = styled.div `
    a {
        color: ${(props) => props.theme.text.primary.color};
    }
`

const Title = styled.div `

`


const MenuLogo = styled(Logo) `
    
`

export default SideMenu