import React, { Component, useEffect, useContext, useState } from 'react';
import { BrowserRouter as Router, useParams } from "react-router-dom";
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Logo from '../../components/Logo'
import Card from '../../components/Card';
import Page, { Center } from '../../components/Page';
import StyledLink from '../../components/Link';
import Spacer from "../../components/Spacer";
import Members from "../../components/Members";
import Circles from "../../components/Circles";
import { getCoordinapeSkill } from 'ice-water/Coordinape';

var _ = require('lodash');

type URLParams = {
    id: string
}

interface SkillProps {

}

const Skill: React.FC<SkillProps> = ({}) => { 
    let { id } = useParams<URLParams>()    
    const [skill, setSkill] = useState<any>()
 
    useEffect(() => { 
        fetchData()
    }, [])

    const fetchData = async (): Promise<void> => {
        const s = await getCoordinapeSkill(id)
        //console.log(s)
        setSkill(s)
    }

    return (
    <Page>
        <Center>
            <Content>
                { skill ? ( 
                    <>
                        <Spacer size='md' />
                        <h1>{skill.name}</h1>
                        <Spacer size='md' />
                
                        <Card>
                            <h3>Talent</h3>
                            <Spacer size='sm' />
                            <Members users={skill.users} />
                        </Card>

                        <Spacer size='md' />             
            
                        <Card>
                            <h3>Circles</h3>
                            <Spacer size='sm' />
                            <Circles ids={_.map(skill.circles, 'id')} />
                        </Card>
                        <Spacer size='md' />                    
                    </>
                ) : (
                    <Loading>
                        Loading ...
                    </Loading>
                )}
            </Content>
            <Spacer size='lg' />
        </Center>
    </Page>
  );
};

export default Skill;

const Content = styled.div `

`

const Loading = styled.div `
    text-align: center;
`