//import './NavBar.css';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import {  } from '@fortawesome/free-regular-svg-icons';
import AccountDropMenu from './AccountDropMenu';
import NavItem from './NavItem';
import Logo from '../Logo';
import AccountButton from 'components/Account/AccountButton';
import { AccountContext } from '../../contexts/Account/AccountContext';
import SideMenu, { SideMenuItem, SideMenuHeader } from 'components/SideMenu';
import { Desktop, Mobile } from "components/Responsive";

// Modals
import useModal from 'hooks/useModal';
import { WalletContext } from 'contexts/Account/WalletContext';
import { MobileStyles } from 'contexts/Theme/Mixins';
import StyledLink from 'components/Link';

var _ = require('lodash');


interface NavBarProps {
    children?: React.ReactNode
}
const NavBar: React.FC<NavBarProps> = ({
    children
}) => {
    const { accountUser } = useContext(AccountContext); 
    const { address } = useContext(WalletContext)


    return (
        <Content>
            <SideMenu />

            <NavBarWrap>
                <NavBarLeft>
                    { children ? (
                        <>{ children }</>
                    ):(
                        <Logo to='/' />
                    )}
                </NavBarLeft>
                <NavBarRight>
                    {/* <StyledLink to='/apps'>
                        Apps &nbsp;&nbsp;&nbsp;
                    </StyledLink> */}

                    {/* <Desktop>
                        <NavItem menu={<ProtocolDropMenu/>}>
                            <NavItemContent>
                                View
                                <FontAwesomeIcon icon={faChevronDown} />
                            </NavItemContent>
                        </NavItem>
                    </Desktop> */}
                    
                    { address ? (
                        <>
                        <NavItem menu={<AccountDropMenu/>}>
                            <NavItemContent>
                                <AccountAddress>
                                    <FontAwesomeIcon icon={faCircle} /> 
                                    {address}
                                </AccountAddress>
                                <FontAwesomeIcon icon={faChevronDown} />
                            </NavItemContent>
                        </NavItem>
                        </>
                    ):(
                        <>
                            <AccountButton />
                        </>
                    )} 
                </NavBarRight>
            </NavBarWrap>

            <New>
                <div>
                    Are you new to Icewater? <StyledLink href='https://icewater.money'>Learn More</StyledLink>
                </div>
            </New>
        </Content>
    )
}
export default NavBar


const New = styled.div `
    position: relative;
    top: ${props => props.theme.topBar.height+10}px;
    background: ${props => props.theme.color.ice};
    width: 100%;
    color: #fff;
    > div {
        padding: 15px;
    }
    a {
        text-decoration: underline;
    }
    z-index: 10;
`


const Content = styled.div `

`

const AccountAddress = styled.div `    
    width: 85px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px 5px;
    svg {
        position: relative;
        top: -3px;
        font-size: 8px;
        color: ${(props) => props.theme.color.h2o};
        margin-left: 0px;
        margin-right: 5px;
    }
    ${MobileStyles(`
        width: 85px;
    `)};
`

const NavItemContent = styled.div `
    display: flex;
    align-items: center;
    > svg {
        margin-left: 7px;
        font-size: 12px;
    }
`

const NavBarLeft = styled.div `
   padding-left: 30px;
   ${MobileStyles(`
        .logo img {
            height: 26px !important;
        }
    `)};
`


const NavBarCenter = styled.div `
    display: flex;
    flex: 1;
    justify-content: center;
    a {
        margin: 0px 15px;
        //padding: 0px 3px 2px 3px;
        &.active {
            color: ${props => props.theme.color.ice};
            filter: brightness(1.2);
            //border-bottom: 2px solid ${props => props.theme.color.ice};
        }
    }
`

const NavBarRight = styled.div `
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > div {
        margin-left: 20px;
    }
`

// height: ${props => props.theme.topBarSize}px;
const NavBarWrap = styled.div `
    z-index: 15;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${props => props.theme.nav.background};
    color: ${(props) => props.theme.color.white};
    height: 72px;
    padding: 0px 20px;

    .nav-item {
        cursor: pointer;
        display: flex;
        align-items: center;
    }
  
    .icon-button {
        width: 30px;
        height: 30px;
        color: ${(props) => props.theme.color.white};
        background-color: #ccc;
        border-radius: 50%;
        padding: 5px;
        margin: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: filter 300ms;

        overflow: hidden;
        img {
            width: 42px;
            border-radius: 50%;
        }
    }

    ${MobileStyles(`
        .icon-button {
            width: 25px;
            height: 25px;
            img {
                width: 36px;
            }
        }
    `)};
  
  
  .icon-button svg { 
    fill: ${(props) => props.theme.color.white};
    width: 20px;
    height: 20px;
  }

  .dropdown-account {
    right: -120px;
  }
  .dropdown-stats {
    right: -120px;
  }
  
  .dropdown {
    position: absolute;
    top: ${props => props.theme.topBar.height}px;
    
    width: 300px;
    transform: translateX(-45%);
    background-color: #fff;
    border-radius: 5px;
    padding: 1rem;
    overflow: hidden;
    transition: height 500ms ease;
    filter: drop-shadow(0 0.3rem 0.35rem rgba(0, 0, 0, 0.5));
  }
  
  .menu {
    width: 100%;
  }
  
  .menu-item,
  .menu-item-nav a {
    color: ${(props) => props.theme.color.white};
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    transition: background 300ms;
    padding: 0.5rem;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
  }

  .menu-item-nav a.active {
    background: #eee;
    .icon-button {
        //background: ${(props) => props.theme.color.h2o};
    }
  }

  .menu-item-content {
    margin-left: 5px;
  }

  .menu-item-content {
      display: block;
  }

  a.menu-item {
      text-decoration: none;
  }
  
  .menu-item .icon-button {
    transition: background 300ms;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  
  .menu-item .icon-button:hover {
    filter: none;
  }
  
  .menu-item:hover,
  .menu-item-nav a:hover {
    background-color: #eee;
    .icon-button {
        //background: ${(props) => props.theme.color.h2o};
    }
  }
  
  .icon-right {
    margin-left: auto;
  }
  
  .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all 300ms ease;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all 300ms ease;
  }
  
  
  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all 300ms ease;
  }
  .menu-secondary-exit {
  
  }
  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all 300ms ease;
  }
`