import React from 'react'
import styled from 'styled-components'
import { BorderRadius, DropShadow, GlassBackground } from '../../contexts/Theme/Mixins'


interface CardData {  
  variant?: 'default' | 'glass'
}

const Card: React.FC<CardData> = ({ children, variant="default" }) => (
  <StyledCard className={`${variant} card`}>
    {children}
  </StyledCard>
)

// ${DropShadow()};
const StyledCard = styled.div`  
    max-width:100%;
    background-color: ${props => props.theme.card.background};
    padding: ${props => props.theme.spacing[4]}px;
    ${BorderRadius()};

    border: 1px solid #e3e3e3;
  
    &.glass {
        backdrop-filter: blur(5px); 
        background-color: rgba(255, 255, 255, 0.2);
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
`

export default Card