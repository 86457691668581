import React, { Component, useEffect, useContext, useState } from 'react';
import { BrowserRouter as Router, useParams } from "react-router-dom";
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Logo from '../../components/Logo'
import Card from '../../components/Card';
import Page, { Center } from '../../components/Page';
import StyledLink from '../../components/Link';
import Spacer from "../../components/Spacer";
import Header from "../../components/Header";
import Skills from "../../components/Skills";
import { DateTime } from "luxon";

import Members from "../../components/Members";

import Button from '../../components/Button';
import { ThemeContext, ThemeContextProps, themes } from '../../contexts/Theme/Theme';

import Config from '../../config'
import Avatar from '../../components/Avatar';
import Link from '../../components/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { faAdjust } from '@fortawesome/free-solid-svg-icons';
import EpochStatus from './Components/EpochStatus';
import { getCoordinapeCircle, getCoordinapeSkillsByCircle, getCoordinapeUsersByCircle } from 'ice-water/Coordinape';
import { WalletContext } from 'contexts/Account/WalletContext';


type URLParams = {
    id: string
}

interface CircleProps {

}

const Circle: React.FC<CircleProps> = ({}) => { 
    let { id } = useParams<URLParams>()    
    const { address } = useContext(WalletContext)

    const [circle, setCircle] = useState<any>()
    const [skills, setSkills] = useState<any>()
    const [users, setUsers] = useState<any>()
    const CircleID = parseInt(id)
  
    useEffect(() => { 
        fetchCircle()
    }, [])

    const fetchCircle = async (): Promise<void> => {
        const c = await getCoordinapeCircle(CircleID)
        console.log(c)
        setCircle(c)

        const s = await getCoordinapeSkillsByCircle(CircleID)
        //console.log(s)
        setSkills(s)

        const u = await getCoordinapeUsersByCircle(CircleID)
        //console.log(u)
        setUsers(u)
    }

    return (
    <Page>
        <Spacer size='sm' />

        <Center>

            { circle &&
                <>
                    <Header title={circle.name} subtitle='CIRCLE' img={circle.logo} icon={<FontAwesomeIcon icon={faAdjust} />} />
                    <Spacer size='md' />

                    <Card>  
                        { (circle.epochs && circle.epochs.length > 0 ) &&
                            <Status className={ circle.epochs[0].active ? 'active' : ''}>
                                {/* <h3>Status</h3> */}
                                <EpochStatus epoch={circle.epochs[0]} inline={false} />
                                <Spacer size='md' />
                            </Status> 
                        }

                        <h3>Info</h3> 
                        <Spacer size='sm' />     
                        <div>
                            { circle.team_sel_text ? (
                                <>{ circle.team_sel_text }</>
                            ):(
                                <>-</>
                            )} 
                        </div>
                    </Card>
                    <Spacer size='md' />
                </>
            }

            { skills &&
                <>
                    <Spacer size='md' />
                    <Card>
                        <h3>Skills</h3>
                        <Spacer size='sm' />
                        <Skills skills={skills.skills} />
                    </Card>
                </>
            }

            { users ? (
                <>
                    <Spacer size='md' />     
                    <Card>  
                        <h3>Members</h3>  
                        <Spacer size='sm' />   
                        <Members users={users} />
                    </Card>
                </>
            ) : (
                <></>
            )}

            {/* { history ? (
                <>
                    <Spacer size='md' />     
                    <Card>  
                        <h3>History</h3>  
                        <Spacer size='sm' />   
                        { history.map((hist:any, index:number) => (
                            <History key={index}>
                                <HistoryDate>{DateTime.fromFormat(hist.dts_created.substring(0, hist.dts_created.indexOf('.')),'yyyy-mm-dd hh:mm:ss').toLocaleString(DateTime.DATE_MED)} - Epoch {hist.epoch_num}</HistoryDate>   
                                <HistorySummary>
                                    +{ hist.tokens } {hist.recipient !== undefined ? (
                                        <Link to={`/profile/${hist.recipient.address}`}>{hist.recipient.name}</Link>
                                    ):(
                                        <Link to={`/profile/${hist.recipient_address}`}>{hist.recipient_address}</Link>
                                    )} from <Link to={`/profile/${hist.sender.address}`}>{hist.sender.name}</Link>
                                    { hist.has_note &&
                                        <HistComment>
                                            <Link href='https://app.coordinape.com/history'>
                                                <FontAwesomeIcon icon={faComment} />
                                            </Link>
                                        </HistComment>
                                    }
                                </HistorySummary>
                            </History>
                        ))}
                    </Card>
                </>
            ) : (
                <></>
            )} */}
        </Center>

        <Spacer size='lg' />
    </Page>
  );
};

export default Circle;

const History = styled.div `
    margin-bottom: 15px;
`

const HistoryDate = styled.div `
    font-size: 12px;
    opacity: .5;
    text-transform: uppercase;
`

const Status = styled.div `

`

const HistorySummary = styled.div `

`
const HistComment = styled.span `
    margin-left: 10px;
`
