import React, { Component, useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Logo from '../../components/Logo'
import Card from '../../components/Card';
import Page, { Center } from '../../components/Page';
import StyledLink from '../../components/Link';
import Spacer from "../../components/Spacer";
import Button from '../../components/Button';
import { ThemeContext, ThemeContextProps, themes } from '../../contexts/Theme/Theme';
import { AccountContext } from '../../contexts/Account/AccountContext';

import Enter from './components/Enter';
import Profile from '../Profile'
import Community from '../Community'

import { WalletContext } from 'contexts/Account/WalletContext';

const Home: React.FC = ({  }) => {
    const { theme } = useContext(ThemeContext) as ThemeContextProps;
    const { address } = useContext(WalletContext)

    /*
    useEffect(() => {
        fetchData()
    },[])

    const fetchData = async () => {

    }
    */

    return (
        <Page>  
            { !address &&
                <>
                <Enter/>       
                </>
            }
            <Center>
                <Community />
            </Center>
        </Page>
    )
};

export default Home;

