export declare enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42
}

export type ConfigurationProps = {
    name: string
    version: number
    chainId: number
    refreshInterval: number
    logoUrl: string
    InfuraID: string
    defaultProvider: string
    coordinapeToken: string
}

const configurations: { [env: string]: ConfigurationProps } = {
    development: {
        name: 'IceWater',
        version: 1,
        chainId: 4,
        refreshInterval: 120000,
        logoUrl: "https://app.icewater.money/logo512.png",
        InfuraID: "ca7f238b94074f9286655058d6fdb191",
        defaultProvider: "https://rinkeby.infura.io/v3/8590d5ef159f4f7c877ea55c2e0d2213",
        coordinapeToken: '16918|NN6U9Y79WEwqZG65YTKOsaGbnOdt9vNehyJuzkYZ' //'5762|p69JYICcytRJfVPhCi0Ae9vtBRHBjUVAKAj9emrZ' // expires ~ Feb 11
    },
    production: {
        name: 'IceWater',
        version: 1,
        chainId: 4,
        refreshInterval: 120000,
        logoUrl: "https://app.icewater.money/logo512.png",
        InfuraID: "ca7f238b94074f9286655058d6fdb191",
        defaultProvider: "https://rinkeby.infura.io/v3/8590d5ef159f4f7c877ea55c2e0d2213",
        coordinapeToken: '16918|NN6U9Y79WEwqZG65YTKOsaGbnOdt9vNehyJuzkYZ' //'5762|p69JYICcytRJfVPhCi0Ae9vtBRHBjUVAKAj9emrZ'
    },
};

export default configurations[process.env.NODE_ENV || "development"];
