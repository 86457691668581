import React, { Component, useEffect, useContext, useState } from 'react';
import { BrowserRouter as Router, useParams } from "react-router-dom";
import styled from 'styled-components';
import Card from '../../components/Card';
import Page, { Center } from '../../components/Page';
import Spacer from "../../components/Spacer";
import Skills from "../../components/Skills";
import Header from "../../components/Header";
import NFTGallery from '../../components/NFTGallery';
import { getUserRewards } from '../../ice-water/Galaxy';
import Circles from '../../components/Circles';
import { getCoordinapeUser } from 'ice-water/Coordinape';
import { WalletContext } from 'contexts/Account/WalletContext';
var _ = require('lodash');

type URLParams = {
    id: string
}

interface ProfileProps {
    uid?: string | null
}

const Profile: React.FC<ProfileProps> = ({
    uid = null
}) => { 
    const { address } = useContext(WalletContext)
    let { id } = useParams<URLParams>()    
    const [user, setUser] = useState<any>()
    const [galaxyCampaigns, setGalaxyCampaigns] = useState<any>()

    if ( uid !== null && address ) {
        id = address
    }

    useEffect(() => { 
        fetchProfile()
    }, [])

    useEffect(() => { 
        fetchProfile()
    }, [id])

    const fetchProfile = async (): Promise<void> => {    
        const u = await getCoordinapeUser(id)
        //console.log(u)
        setUser(u)

        const campaigns = await getUserRewards(id)
        setGalaxyCampaigns(campaigns)
    }
    

    return (
    <Page>  
        <Center>
        { user ? (
            <UserProfile>  
                <Spacer size='md' /> 

                <Header title={user.profile.name} subtitle={user.address} img={user.profile.avatar} />
                
                { user.profile.bio &&
                    <>
                        <Spacer size='md' />
                        <Card>
                            <h3>Bio</h3>
                            <Spacer size='sm' />
                            { user.profile.bio }
                        </Card>
                    </>
                }
                
                { user.profile.skills &&
                    <>
                        <Spacer size='md' />
                        <Card>
                            <h3>Skills</h3>
                            <Spacer size='sm' />
                            <Skills skills={user.profile.skills} />
                        </Card>
                    </>
                }

                { user.circles &&
                    <>
                        <Spacer size='md' />
                        <Card>
                            <h3>Circles</h3>
                            <Circles ids={_.map(user.circles, 'id')} />
                        </Card>

                        <Spacer size='md' />
                        <Card>
                            <h3>Total Tokens</h3>
                            <Total>
                            { user.tokens }
                            </Total>
                        </Card>
                        <Spacer size='md' />
                    </>
                }

            { (galaxyCampaigns && galaxyCampaigns.length > 0) ? (
                <>  
                    <Spacer size='md' />
                    <h2>NFT Rewards</h2>  
                    <Spacer size='sm' /> 
                    <NFTGallery title='NFT Rewards' campaigns={galaxyCampaigns} />
                </>
            ) : (
                <></>
            )}

            </UserProfile>
        ) : (
            <Loading>
                Loading ...
            </Loading>
        )}
        </Center>
        <Spacer size='lg' /> 
    </Page>
  );
};

export default Profile;

const Total = styled.div `
    font-size: 36px;
`


const UserProfile = styled.div `

`


const Loading = styled.div `
    padding: 100px;
    text-align: center;
`

