import React, { Component, useEffect, useContext, useState } from 'react';
import { BrowserRouter as Router, useParams } from "react-router-dom";
import styled from 'styled-components';
import Card from '../../components/Card';
import Page from '../../components/Page';
import StyledLink from '../../components/Link';
import Spacer from "../../components/Spacer";
import Button from '../../components/Button';

import { ThemeContext, ThemeContextProps, themes } from '../../contexts/Theme/Theme';
import { AccountContext } from '../../contexts/Account/AccountContext';


import Suits from './Campaigns/SuitOrSailer';

type URLParams = {
    id: string
}

interface CampaignProps {

}

const Skill: React.FC<CampaignProps> = ({}) => { 
    let { id } = useParams<URLParams>()    
    const { accountUser } = useContext(AccountContext)


    return (
    <Page>
        <Content>
            { id === 'sailor' &&
                <Suits/>
            }      
        </Content>
        <Spacer size='lg' />
    </Page>
  );
};

export default Skill;

const Content = styled.div `
    padding-top: 60px;
`