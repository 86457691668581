import React, { useEffect } from 'react';
import styled from 'styled-components';
import Link from '../Link';
import { stringToURLSlug } from '../../utils/utils'

interface SkillsProps {
    skills: string[]
}

const Skills: React.FC<SkillsProps> = ({
    skills
}) => { 

    return (
    <Content>  
        {skills.map((skill:string, index:number) => (
            <Skill key={index}>
                <Link to={`/skill/${stringToURLSlug(skill)}`}>
                    { skill }
                </Link>
            </Skill>
        ))}
        <Clear/>
    </Content>
  );
};

export default Skills;

const Content = styled.div `
    max-width: 100%;
`

const Clear = styled.div `
    clear: both;
`

const Skill = styled.div `
    float: left;
    padding: 10px;
    background: #eee;
    border-radius: 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;

    &:hover {
        background: ${props => props.theme.color.ice};
        a {
            color: #fff !important;
        }
    }
`
