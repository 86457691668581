import React from 'react';
import styled from 'styled-components';
import Link from '../Link'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons';


interface AvatarProps {
    img?: string | null
    href?: string |  null
    to?: string | null,
    size?: number,
    background?: string,
    icon?: React.ReactNode,
}

const Avatar: React.FC<AvatarProps> = ({
    img = null,
    href = null,
    to = null,
    size = 50,
    background = '#eee',
    icon = <FontAwesomeIcon icon={faUser} />
}) => { 

    const Image = () => {
        return (
            <>
            { img ? (
                <img src={img} />
            ):(
                <>{icon}</>
            )}
            </>
        )
    }

    if ( href ) {
        return (
            <Link href={href} className='avatar'>
                <Image />
            </Link>
        )
    }

    /*
    if ( to ) {
        return (
            <Link to={to} className='avatar'>
                <Image />
            </Link>
        ) 
    }
    */

    if ( to ) {
        return (
            <Link to={to} className='avatar'>
                <Av className='avatar' 
                    size={size}
                    background={background}
                    >      
                    <Image />
                </Av>
            </Link>
        ) 
    }

    return (
        <Av className='avatar' 
            size={size}
            background={background}
            >      
            <Image />
        </Av>
    );
};

interface StyledProps {
    size: number,
    background: string
}

const Av = styled.div<StyledProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    background: ${props => props.background};

    svg {
        font-size: ${props => props.size * .5}px;
    }

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
    }
`;

export default Avatar;
