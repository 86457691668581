import React from 'react';
import styled from 'styled-components';
import { BorderRadius } from '../../contexts/Theme/Mixins'

import Card from '../Card';
import CardContent from '../CardContent';
import Container from '../Container';

export interface ModalProps {
  style?: 'default' | 'glass';
  onDismiss?: () => void;
}

const Modal: React.FC<ModalProps> = ({ style = "default", children }) => {
  return (
    <Container size="sm">
      <StyledModal className={style}>
        <Card variant={style}>
          <CardContent>{children}</CardContent>
        </Card>
      </StyledModal>
    </Container>
  );
};

const StyledModal = styled.div`
  ${BorderRadius()};
  position: relative;
  background: ${(props) => props.theme.modal.background};
  box-shadow: 24px 24px 48px -24px ${(props) => props.theme.global.shadowColor};
  color: ${(props) => props.theme.text.primary.color};

  &.glass > div {
    background-color: rgba(52, 53, 62, 0.92);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);  
    -webkit-backdrop-filter: blur(5px);
  }
`;

export default Modal;
