import React, { useCallback } from 'react';
import styled from 'styled-components';
import StyledLink from '../Link';

interface Props {
    title?: string,
    children?: React.ReactNode
}
  
export const SideMenuSection: React.FC<Props> = ({
    title,
    children
  }) => {  

    return (
        <Content>
            { title &&
                <Header>
                    { title }
                </Header>
            }
            <Items>
                { children }
            </Items>
        </Content>
    )
}

const Content = styled.div `
    margin-bottom: 25px;
`

const Header = styled.div `
    font-size: 12px;
    text-transform: uppercase;
    padding: 10px 25px;
    color: ${(props) => props.theme.text.primary.color};
    padding: 20px 25px 10px 25px;
    border-top: 1px solid #eee;
`

const Items = styled.div `
    > * {
        display: block;
        padding: 10px 25px;
    }
    .active {
        color: ${(props) => props.theme.color.ice};
        //border-bottom: 2px solid ${(props) => props.theme.color.ice};
    }
`

