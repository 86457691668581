import React, { Component, useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Page from '../../../components/Page';
import StyledLink from '../../../components/Link';
import Spacer from "../../../components/Spacer";
import Button from '../../../components/Button';
import { ThemeContext, ThemeContextProps, themes } from '../../../contexts/Theme/Theme';
import { AccountContext } from '../../../contexts/Account/AccountContext';

import water from '../../../assets/water.png';
import { MobileStyles } from 'contexts/Theme/Mixins';

const Enter: React.FC = ({  }) => {
  const { theme } = useContext(ThemeContext) as ThemeContextProps;
  const { accountUser } = useContext(AccountContext);

  return (  
    <Content>
        <StyledLink to='/c/sailor'>
            <Sphere>
                <Text>
                <svg viewBox=''>
                    <defs>
                        <linearGradient id="MyGradient">
                            <stop offset="5%"  stopColor="#24a9a3"/>
                            <stop offset="95%" stopColor="#0191c7"/>
                        </linearGradient>
                    </defs>
                    
                    <path id='curve' d="M80,160	A50,50 0 1,1 81,160"/>
                    <text>
                    <textPath xlinkHref="#curve" className='theText' startOffset="90">
                        ENTER THE HYRDROSPHERE
                    </textPath>
                    </text>
                </svg>
                </Text>

                <Circle>
                    <img src={water} /> 
                </Circle>
            </Sphere>
        </StyledLink>
    </Content>
  );
};

export default Enter;

const Content = styled.div `
  margin-top: 30px;
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #aaa
  }
  svg {
    position: relative;
    left: 485px;
    top: 140px;
    transform: scale(5.5)
  }

  cursor: pointer;

    ${MobileStyles(`
         margin-top: 0px;
         height: 400px;
    `)}
`

const Sphere = styled.div `
    position: relative;
    ${MobileStyles(`
        top: -80px;
        transform: scale(.55)  
    `)};

    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    
    &:hover {
        filter: brightness(1.1);
    }
`

const Text = styled.div ` 
  path {
    fill: transparent;
  }

  .theText {
    font-size: 8px;
    font-weight: bold;
    fill:url(#MyGradient);
    letter-spacing: 1px;
  }
`

const Circle = styled.div `
    height: 500px;
    width: 500px;
    background-color: #0092c6;
    border-radius: 50%;
    border: 5px solid #0092c6;
    overflow: hidden;
    
`
