import React, { useContext } from 'react'
import styled from 'styled-components'
import Footer from '../Footer'
import TopBar from '../TopBar'

import NavBar from '../NavBar'

import { AccountContext } from '../../contexts/Account/AccountContext';
import Enter from '../../views/Home/components/Enter';

import bg from '../../assets/watercolor/iw-community.png';



type PageProps = {
  className?: string,
  topBar?: string,
  children?: React.ReactNode,
};

const Page: React.FC<PageProps> = ({ 
  className = '', 
  children 
}) => {
  const { accountUser } = useContext(AccountContext)
  return (
    <Main className={className}>
        <NavBar/>

        <Content className='styledMain'>


            {/* <Backgrounds>
                <Sky />
                <Sea />
            </Backgrounds> */}

            {children}

        {/* { accountUser ? (
            <>
            {children}
            </>
        ) : (
            <>
            <Enter/> 
            </>
        )}      */}
        </Content>

        <Footer />
    </Main>
  )
}
export default Page

const Backgrounds = styled.div `
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 100%;
    z-index: -1;

    background: rgb(9,152,199);
    background: radial-gradient(circle, rgba(9,152,199,1) 0%, rgba(6,24,50,1) 100%);
`

const Sky = styled.div `
    postion: absolute;
    top: 0px;
    left: 0px;
    height: 300px;
    width: 100%;
    background: rgb(51,185,201);
    background: linear-gradient(0deg, rgba(51,185,201,1) 0%, rgba(73,221,209,1) 100%);
`

const Sea = styled.div `
    postion: absolute;
    top: 300px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: rgb(9,152,199);
    background: linear-gradient(180deg, rgba(9,152,199,1) 0%, rgba(6,24,50,1) 100%);
`

type CenterProps = {
    className?: string,
    children?: React.ReactNode,
}

export const Center: React.FC<CenterProps> = ({ 
    className = 'center', 
    children 
}) => {
    return (
        <CenterColumn className={className}>
            <CenterInner>
                {children}
            </CenterInner>
        </CenterColumn>
    )
}



const CenterColumn = styled.div `
    max-width: 800px;
    width: 100%;
    margin-top: ${props => props.theme.topBar.height}px;
`

const CenterInner = styled.div `
    margin: 20px;
`

const Main = styled.div`
  position: relative;
  overflow: hidden;
  background: #eee;
//   background-color: ${props => props.theme.page.background};
//   background: url("${bg}"), ${props => props.theme.page.background}; 
  background-size:     contain;
  background-repeat:   no-repeat;
  background-position: top center; 

  color: ${props => props.theme.text.primary.color};

`
/*
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
  @keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
  }
*/


const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${props => props.theme.topBar.height}px);  
  z-index: 2;
`