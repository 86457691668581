import React, { useEffect } from 'react';
import styled from 'styled-components';

interface EpochStatusProps {
    epoch: any,
    size?: number,
    inline?: boolean
}

const EpochStatus: React.FC<EpochStatusProps> = ({
    epoch,
    size = 18,
    inline = true
}) => { 

    if ( inline ) {
        return (
            <Content className='status stacked' size={size}> 

                <h6>Epoch { epoch.number }</h6>

                { epoch.active ? (
                    <>
                    <h6><span>ends { epoch.end_relative }</span></h6>
                    </>
                ):(
                    <>
                        { epoch.past &&
                            <>
                            </>
                        }
                        { epoch.future &&
                            <>
                            <h6>starts { epoch.start_relative }</h6>
                            </>
                        }
                        {/* { epoch.past &&
                            <>
                            <h6>ended { epoch.end_relative }</h6>
                            </>
                        }
                        { epoch.future &&
                            <>
                            <h6>starts { epoch.start_relative }</h6>
                            </>
                        } */}
                    </>
                )}
            </Content>
        )
    }

    return (
    <Content className='status' size={size}>  
        { epoch.active ? (
            <>
            <h5>Active</h5>
            <h6>Epoch { epoch.number } ends { epoch.end_relative}</h6>
            </>
        ):(
            <>
            <h5>Inactive</h5>
            <h6>Next epoch { epoch.end_relative}</h6>
            </>
        )}
    </Content>
  );
};

export default EpochStatus;

interface StatusStyledProps {
    size: number
}
  

const Content = styled.div <StatusStyledProps> `
    h5 {
        font-size: ${props => props.size}px;
    }
    h6 {
        font-size: ${props => props.size * .7 }px;
        font-weight: normal;
        text-transform: uppercase;
        span {
            font-weight: bold;
            color: ${props => props.theme.buttons.primary.background};
        }
    }
    h5, h6 {
        display: block;
    }
    &.stacked {
        h6 {
            font-size: 11px;
        }
    }
`
