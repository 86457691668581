//import './NavBar.css';
import React from 'react';
import { NavLink } from 'react-router-dom'

interface DropdownItemProps {
    children?: React.ReactNode,
    leftIcon?: React.ReactNode,
    rightIcon?: React.ReactNode,
    goToMenu?: string,
    href?: string,
    to?: string
    onClick?: () => void
}
const DropdownItem: React.FC<DropdownItemProps> = ({
    children, leftIcon, rightIcon, goToMenu, href = '', to, onClick}) => { 

    if ( to ) {
        return (
            <div className="menu-item-nav">
                <NavLink to={to} exact>
                    <span className="icon-button">{leftIcon}</span>
                    <span className="menu-item-content">{children}</span>
                    <span className="icon-right">{rightIcon}</span>
                </NavLink>
            </div>
        )
    }

    if ( goToMenu || href) {
        var target = "_blank"
        if ( !href ) {
            href = "#"
            target = "_self"
        }
        return (
            // <a href="#" className="menu-item" onClick={() => goToMenu && setActiveMenu(goToMenu)}>
            <a href={href} target={target} className="menu-item">
                <span className="icon-button">{leftIcon}</span>
                {children}
                <span className="icon-right">{rightIcon}</span>
            </a>
        )
    } else {
        return (
            <div className="menu-item" onClick={onClick}>
                <span className="icon-button">{leftIcon}</span>
                {children}
                <span className="icon-right">{rightIcon}</span>
            </div>
        )
    }      
}

export default DropdownItem