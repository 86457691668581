import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Card from '../../../components/Card';

import Suit from './suit.jpg'
import Suit2 from './suit03.jpg'
import Sailor from './sailor.jpg'
import Sailor2 from './sailor02.jpg'
import Fleet from './fleet.jpg'

import Spacer from '../../../components/Spacer';
import Button from '../../../components/Button';
import { BorderRadius, MobileStyles } from 'contexts/Theme/Mixins';
import StyledLink from 'components/Link';


import { Fade } from "react-awesome-reveal";

var _ = require('lodash');

interface CampaignProps {

}

//const Fade = require('react-reveal/Fade');


const SuitOrSailor: React.FC<CampaignProps> = ({

}) => { 
    const [ejecting, setEjecting] = useState<boolean>(false)
    const [step, setStep] = useState<number>(1)
    const [ejectCount, setEjectCount] = useState<number>(3)
    
    const Dump = () => {
        setEjecting(true)
        setStep(2)
    }


    const Eject = () => {
        function getRandomSite(sites: string[]) {
            return sites[Math.floor(Math.random() * (sites.length -1))];
        }     
        const Sites = [
            "https://www.bankofamerica.com/",
            "https://am.jpmorgan.com/us/en/asset-management/adv/",
            "https://www.irs.gov/",
            "https://www.sec.gov/",
            "https://www.dmv.ca.gov/portal/",
            "https://www.nyse.com/index",
            "https://www.schwab.com/",
            "https://www.xfinity.com/overview",
            "https://www.hbs.edu/Pages/default.aspx",
            "https://www.federalreserve.gov/",
            "https://welcome.wf.com/checking-account/?ef_id=%7Bgclid%7D:G:s&gclid=29881a8cf0031e14835a7b062c58fce8&gclsrc=3p.ds",
            "https://robinhood.com/us/en/",
            "https://www.goldmansachs.com/",
            "http://www.freddiemac.com/",
            "https://www.fanniemae.com/",
            "https://www.citi.com/",
            "https://www.usbank.com/index.html",
            "https://www.db.com/index?language_id=1",
            "https://tether.to/",
            "https://www.blackrock.com/us/individual",
            "https://www.sequoiacap.com/",
            "https://www.kleinerperkins.com/",
            "https://www.accel.com/",
            "https://www.gv.com/",
            "http://www.benchmark.com/",
            "https://a16z.com/",
            "https://www.softbank.jp/en/",
            "https://www.tudor.com/",
            "https://prime.fidelity.com/",
            "https://www.quantumgroupus.com/Industries/Investments%20Fund/aboutus.html",
            "https://www.bridgewater.com/",
            "https://schiffradio.com/"
        ]
        const Site = getRandomSite(Sites)
        window.location.href = Site
        setTimeout(() => {
            Reset()
        }, 1000)
    }

    const Reset = () => {
        setEjecting(false)
        setStep(1)
    }

    const timer: { current: NodeJS.Timeout | null } = useRef(null)
    useEffect(() => {
        if ( ejecting ) {
            setEjectCount(3)
            timer.current = setTimeout(() => {
                setEjectCount(2)
                timer.current = setTimeout(() => {
                    setEjectCount(1)
                    timer.current = setTimeout(() => {
                        setEjectCount(0)
                        Eject()
                    }, 1000)
                }, 1000)
            }, 2500)
        } else {
            if ( timer.current ) {
                clearTimeout(timer.current as NodeJS.Timeout)
                timer.current = null
            }
        }
    }, [ejecting])

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [step])

    return (
        <Content>  
            <Spacer size='lg' />

            { step == 1 &&
                <Step1>          
                    <h2>Welcome! Let's see what you're made of...</h2>
                    <Spacer size='sm' />
                    
                    <TwoCols>
                        <Chooser>

                            <Fade triggerOnce={true} direction='left' delay={500}>
                                <Card>
                                    <div onClick={()=>{setStep(3)}}>      
                                        <img src={Sailor2} />

                                        <Spacer size='md' />
                                        <h3>Are you a Sailor?</h3>

                                        <Attrs>
                                            <Attr>
                                                Decentralization matters.
                                            </Attr>

                                            <Attr>
                                                I pay for goods and services with decentralized currency.
                                            </Attr>

                                            <Attr>
                                                Everyone should have equal access to the financial system.
                                            </Attr>

                                            <Attr>
                                                I'm searching for a better way.
                                            </Attr>
                                        </Attrs>

                                
                                        <Spacer size='md' />
                                        <Fade triggerOnce={true} direction='up' delay={1500}>
                                            <div className='buttons'>
                                                <Button text="I'm a sailor" display='block' />
                                            </div>
                                        </Fade>
                                    </div>
                                </Card>
                            </Fade>

                            <Fade triggerOnce={true} direction='right' delay={500}>
                                <Card>
                                    <div onClick={Dump}>
                                        <img src={Suit2} />
                                        <Spacer size='md' />
                                        <h3>Are you a Suit?</h3>

                                        <Attrs>
                                            <Attr>
                                                I'm in it for the money.
                                            </Attr>
                                    
                                            <Attr>
                                                I rely on centralized fiat pegged stabelcoins.
                                            </Attr>
                                        
                                            <Attr>
                                                I invest in protocols with impressive partner pages.
                                            </Attr>
                                    
                                            <Attr>
                                                I am satisfied with the status quo.
                                            </Attr>
                                        </Attrs>
    
                                
                                        <Spacer size='md' />
                                        <Fade triggerOnce={true} direction='up' delay={1500}>
                                            <div className='buttons'>
                                                <Button text="I'm a suit" display='block' />
                                            </div>
                                        </Fade>
                                    </div>
                                </Card>
                            </Fade>

                            
                        </Chooser>
                    </TwoCols>
                </Step1>
            }

            { step == 2 &&
                <Step>  
                    <Fade triggerOnce={true} direction='up'>
                        <Spacer size='md' />
                        <h3>Hmm.. Well then you don't belong here.</h3>
                    </Fade>  

                    <Fade triggerOnce={true} direction='up' delay={1500}>
                        <>
                            <EjectPrep>
                                <Spacer size='lg' />
                                <h1>Prepare to be ejected...</h1>
                                <div className='count'>{ejectCount}</div>
                            </EjectPrep>
                            <Spacer size='sm' />
                            <Cancel onClick={Reset}>Cancel</Cancel>
                        </>
                    </Fade>         
                </Step>
            }
                     
            { step == 3 &&
                <Step>
                    <Resetter onClick={Reset}>Reset</Resetter>

                    <Fade triggerOnce={true} direction='up'>
                        <Same>
                            <h1>We're speaking the same language.</h1>
                            <Spacer size='md' />
                        </Same>
                    </Fade>

                    <Fade triggerOnce={true} direction='up' delay={500}>
                        <FleetWrap>
                            <img src={Fleet} />
                        </FleetWrap>
                    </Fade>

                    <Fade triggerOnce={true} direction='up' delay={1000}>
                        <Same>
                            <Spacer size='lg' />
                            <h2 className='center'>Join the fleet fighting for financial freedom</h2>
                            <h4 className='center'>Escape the hopelessness of traditional finance</h4>
                        </Same>

                        <Same>
                            <Spacer size='md' />
                            <ul>
                                <li>
                                    Icewater can help you achieve financial freedom without being tethered to the suits.
                                </li>
                                <li>
                                    H2O is a safe and stable currency that does not rely on any oracle.
                                </li>
                                <li>
                                    ICE is an inflation protected asset.
                                </li>
                                <li>
                                    STM is Allows anyone to benefit from the increased usage of H2O.
                                </li>
                            </ul>
                        </Same>
                    </Fade>

                    <Fade triggerOnce={true} direction='up' delay={1000}>
                        <Join>
                            <Same>
                                <Spacer size='lg' />
                                <h2 className='center'>Get whitelisted for an Airdrop at our launch this summer by purchasing one of these sailboat NFT’s.</h2>
                                <br/>
                                <Button text='Buy NFT' href='https://icewater.nftify.network/' />
                            </Same>

                            <Spacer size='lg' />
                            <Discord>
                                Join our <StyledLink href='https://discord.gg/yBqPTXbpEz'>Discord</StyledLink> or check out our <StyledLink href='https://icewater.money'>FAQ</StyledLink> to learn more.
                            </Discord>
                        </Join>
                    </Fade>
                </Step>
            }


            <Spacer size='lg' />

        </Content>
    );
};

export default SuitOrSailor;

const Content = styled.div `
    h2, h3, h4, h5, h6 {
        font-family: 'Montserrat', sans-serif;
    }
    ${MobileStyles(`
        padding: 0px 15px;
        h1 {
            margin-top: 10px;
            font-size: 22px;
        }
        h2 {
            font-size: 20px;
        }
        h1.center, h2.center, h3.center, h4.center {
            text-align: center;
        }
    `)};

    ul {
        li {
            margin-left: 20px;
            margin-bottom: 10px;
        }
    }
`
const FleetWrap = styled.div `
    img {
        ${BorderRadius()}
        max-width: 100%;
    }
`

const Join = styled.div `
    h4 {
        margin-top: 5px;
    }
    .btn {
        width: 200px;
    }
    ${MobileStyles(`
        .btn {
            width: 100%;
        }
    `)};
`

const Discord = styled.div `
    a {
        color: ${props => props.theme.color.ice};
    }
`

const Attrs = styled.div `
    height: 140px;
    ${MobileStyles(`
        height: auto;
    `)};
    div:last-child {
        margin-bottom: 0px;
    }
`

const Attr = styled.div `
    margin-bottom: 15px;
    font-size: 16px;
`

const Step = styled.div `
    max-width: 800px;
    margin: 0px auto;
    div.card {
        margin-top: 20px;
    }
    li a {
        color: ${props => props.theme.color.ice};
    }
`

const Step1 = styled.div `
    button {
        width: 100%;
        font-size: 16px;
    }

    ${MobileStyles(`
        h2 {
            font-size: 18px;
        }
    `)};
`

const TwoCols = styled.div `
    display: flex;
    
    ${MobileStyles(`
        display: block;
    `)};
`

const Resetter = styled.div `
    text-align: right;
    cursor: pointer;
`
const Cancel = styled.div `
    text-align: center;
    cursor: pointer;
`

const Same = styled.div `
    h2 {
        margin-bottom: 5px;
    }
`


const Chooser = styled.div `
    display: flex;
    max-width: 1000px;
    margin: 0px auto;

    > div:first-child {
        margin-right: 5px;
    }
    > div:last-child {
        margin-left: 5px;
    }

    div.card {
        overflow: hidden;
    }

    h3 {
        margin-bottom: 15px;
    }

    img {
        max-width: 100%;
    }
    ${MobileStyles(`
        display: block;

        div.card {
            margin-bottom: 10px;
        }

        > div:first-child {
            margin-right: 0px;
        }
        > div:last-child {
            margin-left: 0px;
        }
    `)};
`


const EjectPrep = styled.div `
    text-align: center;
    .count {
        padding: 25px 0px;
        font-size: 60px;
    }
`
