import { initializeApp } from "firebase/app"; // no compat for new SDK
import { getDatabase, ref } from 'firebase/database'
import { getAuth, connectAuthEmulator, onAuthStateChanged } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFunctions } from "@firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


type firebaseConfigTypes = {
    apiKey: string,
    authDomain: string,
    databaseURL: string,
    projectId: string,
    storageBucket: string,
    messagingSenderId: string,
    appId: string,
    measurementId: string
}

const firebaseConfigs: { [env: string]: firebaseConfigTypes } = {
    // firebase app: Website Dev
    development: {
        apiKey: "AIzaSyBQc9Qo179-O1xwrsqLpgjsctTSVAIEINU",
        authDomain: "icewater-307417.firebaseapp.com",
        databaseURL: "https://icewater-307417-default-rtdb.firebaseio.com",
        projectId: "icewater-307417",
        storageBucket: "icewater-307417.appspot.com",
        messagingSenderId: "619383411296",
        appId: "1:619383411296:web:a3db62a3bc2150add62905",
        measurementId: "G-48HP0LC9PV"
    },
    // firebase app: Website
    production: {
        apiKey: "AIzaSyBQc9Qo179-O1xwrsqLpgjsctTSVAIEINU",
        authDomain: "icewater-307417.firebaseapp.com",
        databaseURL: "https://icewater-307417-default-rtdb.firebaseio.com",
        projectId: "icewater-307417",
        storageBucket: "icewater-307417.appspot.com",
        messagingSenderId: "619383411296",
        appId: "1:619383411296:web:a3db62a3bc2150add62905",
        measurementId: "G-48HP0LC9PV"
    },
}

export const firebaseConfig = firebaseConfigs[process.env.NODE_ENV || "development"]

const firebaseApp = initializeApp(firebaseConfig);

export const database = getDatabase(firebaseApp)

export const auth = getAuth(firebaseApp);

export const functions = getFunctions(firebaseApp);

export const analytics = getAnalytics(firebaseApp); 

if ( process.env.NODE_ENV == 'development' ) {
    console.log('development')
    connectAuthEmulator(auth, "http://localhost:9099");
}

export function snapshotToArray(snapshot:any) {
    return Object.entries(snapshot).map(e => Object.assign(e[1], { key: e[0] }));
};
//export const rootRef = ref(database, 'root')

export default firebaseApp;
