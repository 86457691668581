import React, { Component, useEffect, useContext, useState } from 'react';
import { BrowserRouter as Router, useParams } from "react-router-dom";
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Card from '../../components/Card';
import Page, { Center } from '../../components/Page';
import StyledLink from '../../components/Link';
import Spacer from "../../components/Spacer";
import Button from '../../components/Button';

import Circles from "../../components/Circles";
import Skills from "../../components/Skills";
import Members from "../../components/Members";
import { Desktop, Mobile } from "../../components/Responsive";

import { ThemeContext, ThemeContextProps, themes } from '../../contexts/Theme/Theme';
import { AccountContext } from '../../contexts/Account/AccountContext';
import { getCampaigns, getNFTinfo } from '../../ice-water/Galaxy';


import Config from '../../config'
import Avatar from '../../components/Avatar';
import Link from '../../components/Link';
import NFTGallery from '../../components/NFTGallery';
import { WalletContext } from 'contexts/Account/WalletContext';
import { getCoordinapeCircles, getCoordinapeUsers } from 'ice-water/Coordinape';
import { MobileStyles } from 'contexts/Theme/Mixins';
import Loading from 'components/Loading';

var _ = require('lodash');

type URLParams = {
    id: string
}

interface CommunityProps {

}

const Community: React.FC<CommunityProps> = ({}) => { 
    let { id } = useParams<URLParams>()    
    const { address, isOnChain, disconnect } = useContext(WalletContext)
    const { accountUser } = useContext(AccountContext)
    const [circles, setCircles] = useState<any>()
    const [users, setUsers] = useState<any>()
    const [members, setMembers] = useState<any>()
    const [skills, setSkills] = useState<any>()

    const [galaxyCampaigns, setGalaxyCampaigns] = useState<any>()

    useEffect(() => { 
        fetchData()
        fetchRewards()
    }, [])

    useEffect(() => { 
        if ( address ) {
            fetchRewards()
        }
    }, [address])

    const fetchRewards = async (): Promise<void> => {
        var campaigns
        if ( address ) {
            campaigns = await getCampaigns(address)
        } else {
            campaigns = await getCampaigns()
        }
        //console.log(campaigns)
        setGalaxyCampaigns(campaigns)
    }

    const fetchData = async (): Promise<void> => {
        const data = await getCoordinapeCircles()
        setCircles(data)

        const users = await getCoordinapeUsers()
        //console.log(users)
        setMembers(users)

        var communitySkills:any = []
        users.forEach((user:any) => {
            if ( user.profile.skills) {
                communitySkills = communitySkills.concat(user.profile.skills)
            }
        });
        setSkills(_.uniq(communitySkills))   
    }

    return (
        <Content>
            <Spacer size='md' />

            { address ? (
                <h1>Community Overview</h1>
            ):(
                <h1>This is our community. Join us!</h1>
            )}

            <Spacer size='md' />
            <Card>  
                <h3>Circles</h3>  
                { circles ? (
                    <>
                    <Spacer size='sm' /> 
                    <Circles ids={_.map(circles, 'id')} />
                    </>
                ):(
                    <Loading />
                )}
            </Card>
            
            <Spacer size='md' />
            <Card>  
                <h3>Skills</h3>  
                { skills ? (
                    <>
                    <Spacer size='sm' /> 
                    <Skills skills={skills} />
                    </>
                ):(
                    <Loading />
                )}
            </Card>

            <Spacer size='md' />
            <Card>  
                <h3>Members</h3>  
                { members ? (
                    <>
                    <Spacer size='sm' /> 
                    <Members users={members} />
                    </>
                ):(
                    <Loading />
                )}
            </Card>

            <Spacer size='lg' />
            <h2>Community Badges</h2>
            <Spacer size='lg' /> 
            { galaxyCampaigns ? (
                <>
                <Spacer size='sm' /> 
                <NFTGallery campaigns={galaxyCampaigns} />
                </>
            ):(
                <Loading />
            )}
        <Spacer size='lg' />
    </Content>
  );
};

export default Community;



const Content = styled.div `
    ${MobileStyles(`
        h1 {
            font-size: 24px;
        }
    `)};
`

const MembersCol = styled.div `
    column-count: 3;
    column-gap: 40px;
    column-fill: balance;

    @media only screen and (max-width: 480px) {
        column-count: 1;
        column-gap: 0px;
    }
`


