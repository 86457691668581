import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

interface ButtonProps {
  children?: React.ReactNode,
  disabled?: boolean,
  href?: string,
  onClick?: () => void,
  size?: 'sm' | 'md' | 'lg',
  text?: string,
  to?: string,
  background?: 'primary' | 'secondary' | 'tertiary' | 'inherit' | 'textbox',
  hoverBackground?: 'primary',
  type?: 'button' | 'submit' | 'reset',
  active?: boolean,
  display?: 'block' | 'inline-block' | 'flex',
  className?: string,
  fontSize?: number
}

const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  href,
  onClick,
  size,
  text,
  to,
  background='primary',
  hoverBackground='primary',
  type='button',
  active,
  className='',
  display='inline-block',
  fontSize=0
}) => {

  let boxShadow: string = ""
  let buttonSize: number
  let buttonPadding: number
  let textFontSize = 14 
  switch (size) {
    case 'sm':
      buttonPadding = 15
      buttonSize = 36
      textFontSize = 14
      break
    case 'lg':
      buttonPadding = 15
      buttonSize = 72
      textFontSize = 16
      break
    case 'md':
    default:
      buttonPadding = 20
      buttonSize = 56
      textFontSize = 15
  }

  if ( fontSize != 0 ) {
    textFontSize = fontSize
  }
      
  return (
    <Content
        boxShadow={boxShadow}      
        disabled={disabled}
        fontSize={textFontSize}
        padding={buttonPadding}
        size={buttonSize}
        active={active}
        background={background}
        className={`${className} btn`}
        hoverBackground={hoverBackground}
        display={display}
    >
        { to && 
            <StyledLink className={className} to={to}>
                <>
                {children}
                {text}
                </>
            </StyledLink>
        }

        { href && 
            <StyledExternalLink className={className} href={href} target="__blank">
                <>
                {children}
                {text}
                </>
            </StyledExternalLink>
        }

        { (!to && !href) &&
            <StyledButton onClick={onClick}>
                <>
                {children}
                {text}
                </>
            </StyledButton>
        }
    </Content>
  )
}

interface StyledButtonProps {
  boxShadow: string,  
  disabled?: boolean,
  active?: boolean,
  fontSize: number,
  padding: number,
  size: number,
  background: string,
  hoverBackground: string,
  display: string
}

const StyledLink = styled(Link)`
  width: auto;
`

const StyledExternalLink = styled.a`

`

const StyledButton = styled.button`

`
// width: ${({display, theme}) => {
//     if ( display === 'block' ) return '100%';
//     return 'auto';
// }};
const Content = styled.div<StyledButtonProps> `
    display: ${props => props.display};

    a {
        height: 36px;
        line-height: 36px;
        text-decoration: none;
    }

    button, a {
        display: flex;
        align-items: center;
        border: 0;
        border-radius: 20px;
        cursor: pointer;
        font-weight: bold;
        font-size: ${props => props.fontSize}px;
        height: ${props => props.size}px;
        justify-content: center;
        outline: none;
        padding-left: ${props => props.padding}px;
        padding-right: ${props => props.padding}px;
        pointer-events: ${props => !props.disabled ? undefined : 'none'};
        width: auto;
        position: relative;

        color: ${({background, theme}) => {
            if ( background === 'primary' ) return theme.buttons.primary.color;
            if ( background === 'secondary' ) return theme.buttons.secondary.color;
            if ( background === 'tertiary' ) return theme.buttons.tertiary.color;
            if ( background === 'textbox' ) return theme.buttons.primary.color;
            return 'inherit';
        }};
    
        opacity: ${({active, background, theme}) => {
            if ( active === true ) {
              return 1;
            }
            if ( active === false ) {
              return .8;
            }
            if ( background === 'inherit' ) {
                return .8;
            }
            return 1;
        }};

        background-color: ${({background, active, theme}) => {
            if ( active === true ) {
              return theme.buttons.primary.background;
            }
            if ( active === false ) {
              return 'inherit';
            }
        
            if ( background === 'primary' ) return theme.buttons.primary.background;
            if ( background === 'secondary' ) return theme.buttons.secondary.background;
            if ( background === 'tertiary' ) return theme.buttons.tertiary.background;
            if ( background === 'textbox' ) return theme.input.background;
        
            return 'inherit';
        }};
        
        &:hover {
            background-color: ${({background, active, theme}) => {
              if ( active === true ) {
                if ( background === 'primary' ) return theme.buttons.primary.background;
                if ( background === 'secondary' ) return theme.buttons.secondary.background;
                if ( background === 'tertiary' ) return theme.buttons.tertiary.background;
                if ( background === 'inherit' ) return theme.buttons.primary.background;
                if ( background === 'textbox' ) return theme.input.background;
              }
        
              if ( active === false ) {
                return 'inherit'
              }
        
              if ( background === 'primary' ) return theme.buttons.primary.backgroundHover;
              if ( background === 'secondary' ) return theme.buttons.secondary.backgroundHover;
              if ( background === 'tertiary' ) return theme.buttons.tertiary.backgroundHover;
              if ( background === 'inherit' ) return theme.buttons.tertiary.backgroundHover;
              if ( background === 'textbox' ) return theme.input.background;
              return theme.buttons.tertiary.background;
            }};
        
            opacity: 1
        }

        -webkit-transition: background 150ms ease-out;  
        -moz-transition: background 150ms ease-out;  
        -o-transition: background 150ms ease-out;  
        transition: background 150ms ease-out; 
    }
`



export default Button